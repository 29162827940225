import React, { Component } from "react";
import "./Dashboard.style.css";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { Dashboard } from "@mui/icons-material";
import { apiOptionWebsite, apiHistoryLastResult } from "../../Service/api";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import moment from "moment";
import Select from "react-select";
import "moment/locale/id"; // without this line it didn't work
moment.locale("id");

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#07213c",
    color: theme.palette.common.white,
    borderRadius: "0px",
    fontSize: 20,
    marginTop: -10,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 20,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const stylesListComent = {
  inline: {
    display: "inline",
  },
};

class DashboardPage extends Component {
  constructor(props) {
    super(props);
    this.reactTable = React.createRef();
    this.state = {
      community: props.community,
      tableData: [],
      tableDisplay: [],
      filter: "",
      groupShow: [],
      selectedGroupId: null,
      openAlertDelete: false,
      itemDeleted: {},
      setOpenAdd: false,
      setOpenEdit: false,
      item_detail: {},
      selectAll: false,
      checked: [],
      selectRowData: [],
      openSelectedRow: false,
      openAlertDeleteAll: false,
      setOpenDialogImport: false,
      dataJson: [],
      uploadLoading: false,
      file: null,
      openValidation: false,
      titleValidation: "",
      titleBody: "",
      showLoadingSubmit: false,
      selectWebsiteId: null,
      optionDataWebsite: [],
      startDate: moment(),
      endDate: moment(),
      historyPrize: [],
      loginInfo: "",
    };
  }

  componentDidMount() {
    this.getRoleData();
  }

  getRoleData = () => {
    let loginInfo = localStorage.getItem("loginInfo");
    loginInfo = JSON.parse(loginInfo);
    // console.log(loginInfo);
    this.setState({
      loginInfo: loginInfo,
    });
    this.doLoadOption();
  };

  doLoadOption = () => {
    apiOptionWebsite()
      .then((res) => {
        // console.log(res);
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            if (this.state.loginInfo.logintype === 2) {
              const results = data.records.filter(({ value: id1 }) =>
                this.state.loginInfo.info.some(
                  ({ id_agent: id2 }) => id2 === id1
                )
              );

              // console.log(results);
              this.setState({
                optionDataWebsite: results,
                selectWebsiteId: results[0],
              });
            } else {
              this.setState({
                optionDataWebsite: data.records,
                selectWebsiteId: data.records[0],
              });
            }

            let resultDateActive = new Date();
            resultDateActive.setDate(resultDateActive.getDate() - 7);
            var back7Day = moment(resultDateActive).format("YYYY-MM-DD");

            let params = {
              agentId: this.state.selectWebsiteId.value,
              startDate: back7Day,
              endDate: this.state.endDate
                .clone()
                .startOf("day")
                .format("YYYY-MM-DD"),
            };
            // console.log(params);
            this.doLoadHistory(params);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doLoadHistory = (params) => {
    this.props.doLoading();
    apiHistoryLastResult(params)
      .then((res) => {
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            let temp = data.records;
            this.setState({ historyPrize: temp });
          } else {
            this.setState({ historyPrize: [] });
          }
        } else {
          this.setState({ historyPrize: [] });
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  handleChangeWebsite = (selectWebsiteId) => {
    this.setState({
      selectWebsiteId: selectWebsiteId,
      historyPrize: [],
    });

    let resultDateActive = new Date();
    resultDateActive.setDate(resultDateActive.getDate() - 7);
    var back7Day = moment(resultDateActive).format("YYYY-MM-DD");

    let params = {
      agentId: selectWebsiteId.value,
      startDate: back7Day,
      endDate: this.state.endDate.clone().startOf("day").format("YYYY-MM-DD"),
    };

    // console.log(params);
    this.doLoadHistory(params);
  };

  getNumberWithSuffix = (number) => {
    const suffixes = ["st", "nd", "rd"];
    const lastDigit = number % 10;
    const suffix =
      lastDigit >= 1 && lastDigit <= 3 ? suffixes[lastDigit - 1] : "th";
    return `${number}${suffix}`;
  };

  renderHistory = () => {
    if (this.state.historyPrize.length > 0) {
      return (
        <div className="cd-time-table">
          <TableContainer component={Paper} style={{ borderRadius: "0px" }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Tanggal</StyledTableCell>
                  <StyledTableCell align="center">Hari</StyledTableCell>
                  {this.state.historyPrize[0].infoBukaan.map(
                    (obj, i) =>
                      i < 3 && (
                        <StyledTableCell align="center">
                          {this.getNumberWithSuffix(i + 1)} Prize
                        </StyledTableCell>
                      )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.historyPrize.map((row) => (
                  <StyledTableRow key={row.date_active}>
                    <StyledTableCell component="th" scope="row">
                      {row.date_active}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {moment(row.date_active).format("dddd")}
                    </StyledTableCell>
                    {row.infoBukaan.map(
                      (obj, i) =>
                        i < 3 && (
                          <StyledTableCell align="center">
                            {obj.nomor}
                          </StyledTableCell>
                        )
                    )}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      );
    } else {
      return (
        <Grid item xs={12}>
          <Paper
            style={{
              borderLeft: 6,
              color: "#FFF",
              maxHeight: 1000,
              padding: 16,
            }}
          >
            <Typography
              variant="subtitle1"
              style={{
                textAlign: "center",
                fontSize: 20,
                color: "#232f5f",
              }}
            >
              Tidak ada riwayat
            </Typography>
          </Paper>
        </Grid>
      );
    }
  };

  render() {
    return (
      <Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper
                style={{
                  borderLeft: 6,
                  borderColor: "#07213c",
                  backgroundColor: "#07213c",
                  maxHeight: 100,
                  padding: 16,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    color: "white",
                  }}
                >
                  <Dashboard style={{ marginRight: 10 }} />
                  <Typography
                    component="span"
                    variant="h1"
                    style={
                      (stylesListComent.inline,
                      {
                        fontSize: 20,
                        color: "#FFF",
                        fontWeight: "bold",
                      })
                    }
                  >
                    Dashboard
                  </Typography>
                </div>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper
                style={{
                  borderLeft: 6,
                  borderColor: "#2f55a2",
                  backgroundColor: "#cccccc",
                  maxHeight: 100,
                  padding: 16,
                  paddingBottom: 24,
                }}
              >
                <div className="contentDate">
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={
                      (stylesListComent.inline,
                      {
                        marginRight: 16,
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        alignSelf: "center",
                        color: "#07213c",
                        fontWeight: "bold",
                      })
                    }
                  >
                    Pilih Website:
                  </Typography>

                  <div style={{ marginRight: 16, width: 300 }}>
                    <Select
                      classNamePrefix="select"
                      placeholder="Pilih Website"
                      value={this.state.selectWebsiteId}
                      onChange={this.handleChangeWebsite}
                      options={this.state.optionDataWebsite}
                    />
                  </div>
                </div>
                <br></br>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <br></br>
        <div className="box-container">{this.renderHistory()}</div>
      </Box>
    );
  }
}
export default DashboardPage;
