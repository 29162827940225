import axios from "axios";
import { webserviceurl } from "./baseUrl";

export function apiLogin(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/login.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiWebsiteList() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/website_list.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiWebsiteInsertUpdate(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/website_insert_update.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiWebsiteUpdate(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/website_update.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiWebsiteGetId(agentId) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/website_get_id.php",
      data: { agentId: agentId },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiOptionWebsite() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/option_website.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiOptionAgent() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/option_agent.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiWebsiteDeleteAll(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/website_delete_all.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiWebsiteDelete(agentId) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/website_delete.php",
      data: { agentId: agentId },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiBannerList(agentId) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/banner_list.php",
      data: { agentId: agentId },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

// export function apiWebsiteBukaanAgent(agentId) {
//   try {
//     const response = axios({
//       method: "post",
//       url: webserviceurl + "/website_agent_bukaan.php",
//       data: { agentId: agentId },
//       headers: {
//         "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
//       },
//     });
//     return response;
//   } catch (error) {
//     throw error;
//   }
// }

export function apiWebsiteBukaanAgent(agentId) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/prize_item_list.php",
      data: { agentId: agentId },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiWebsiteBukaanAgentIndex(agentId, pageIdx, pageSize) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/prize_item_list_index.php",
      data: { agentId: agentId, pagesIndex: pageIdx, pageSize: pageSize },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiWebsiteInitialAgentIndex(agentId, pageIdx, pageSize) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/website_agent_initial_index.php",
      data: { agentId: agentId, pagesIndex: pageIdx, pageSize: pageSize },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiWebsiteConsolationAgentIndex(agentId, pageIdx, pageSize) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/website_agent_consolation_index.php",
      data: { agentId: agentId, pagesIndex: pageIdx, pageSize: pageSize },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiBannerInsertUpdate(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/banner_insert_update.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiHistoryLastResult(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/history_result_list.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiPrizeCreateBukaan(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/prize_create_bukaan.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiPrizeCreateMultiBukaan(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/prize_create_multi_bukaan.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiPrizeUpdateBukaan(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/prize_item_update.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiPrizeDeleteBukaan(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/prize_item_delete.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiInitialCreate(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/initial_create.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiInitialDelete(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/initial_delete.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiInitialUpdate(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/initial_update.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiConsolationCreate(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/consolation_create.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiConsolationDelete(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/consolation_delete.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiConsolationUpdate(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/consolation_update.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiLogoInsertUpdate(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/logo_insert_update.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiWhiteList() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/whitelist_list.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiWhiteListInsert(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/whitelist_insert_update.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiWhiteListUpdate(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/whitelist_update.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiWhiteListGetId(idWhiteList) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/whitelist_get_id.php",
      data: { idWhiteList: idWhiteList },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiWhiteListDeleteAll(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/whitelist_delete_all.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiWhiteListDelete(idWhiteList) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/whitelist_delete.php",
      data: { idWhiteList: idWhiteList },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAdminList(filter) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/admin_list.php",
      data: { filter: filter },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiWhiteListAvailable() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/whitelist_ip_available.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiUpdateIp(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/update_last_ip.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAdminInsert(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/admin_insert_update.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAdminUbahPassword(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/admin_ubah_password.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAdminDelete(id_user) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/admin_delete.php",
      data: { id_user: id_user },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAdminGetDetail(id_user) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/admin_by_phoneno.php",
      data: { id_user: id_user },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAdminDeleteAll(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/admin_delete_all.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAdminUpdate(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/admin_update.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiLogActivityInsert(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/log_activity_insert.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiLogActivity(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/log_activity_list.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiLoginType() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/cp_get_logincptype_list.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGetIp() {
  try {
    const response = axios({
      method: "get",
      url: "https://geolocation-db.com/json/",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiCommunityList() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/cp_community_list_available.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAccountTradingList(filter) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/account_trading_list.php",
      data: { filter: filter },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAccountList() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/accountMt4_list.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiVpsList() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/vps_list.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAccountDelete(code_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/accountMT4_delete.php",
      data: { code_id: code_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAccountUnlink(account_number, vps_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/accountMT4_unlink.php",
      data: { account_number: account_number, vps_id: vps_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAccountDeleteAll(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/accountMt4_delete_all.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiVpsDetail(code_vps) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/vps_detail.php",
      data: { code_vps: code_vps },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiVpsDelete(code_vps) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/vps_delete.php",
      data: { code_vps: code_vps },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiVpsDeleteAll(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/vps_delete_all.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAccountAvailable() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/accountMt4_available.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAccountUpdate(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/accountMt4_update.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGroupList() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/groupList.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiViewList() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/viewList.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAddManageApi(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/telegram_manage_api.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGroupContactList() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/group_list.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGroupContactInput(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/group_insert_update.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGroupContactDelete(group_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/group_delete.php",
      data: { group_id: group_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGrabContact(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/grab_contact_insert.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAllContactList(filter) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/all_contact_list.php",
      data: { filter: filter },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAllContactGroupId(group_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/all_contact_group_id.php",
      data: { group_id: group_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAllContactListNoLimit(filter) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/all_contact_list_nolimit.php",
      data: { filter: filter },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAllContactGroupIdNoLimit(group_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/all_contact_group_id_nolimit.php",
      data: { group_id: group_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiManageApiTelegram() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/manage_api_list.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiManageApiTelegramDelete(manage_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/manage_api_delete.php",
      data: { manage_id: manage_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiManageApiAccount() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/manage_api.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAccountInsertUpdate(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/manage_api_insert.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiJoinGroupInsert(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/telegram_account_group_join.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiJoinGroupList(group_account_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/join_group_list.php",
      data: { group_account_id: group_account_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiJoinGroupDelete(telegram_group_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/telegram_account_group_delete.php",
      data: { telegram_group_id: telegram_group_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiManageDeleteAll(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/manage_api_delete_all.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGroupDeleteAll(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/group_delete_all.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGroupAccountList() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/group_account_list.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGroupAccountInput(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/group_account_insert_update.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGroupAccountUpdate(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/group_account_update.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGroupAccountDelete(group_account_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/group_account_delete.php",
      data: { group_account_id: group_account_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGroupAccountDeleteAll(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/group_account_delete_all.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAccountTelegram() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/account_telegram.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGroupTelegramAccount(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/add_telegram_account_group.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiUpdateAccountBanned(manage_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/update_account_banned.php",
      data: { manage_id: manage_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiUpdateAccountSession(manage_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/update_account_session.php",
      data: { manage_id: manage_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiUpdateAccountFlood(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/update_account_flood.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiUpdateMaxInvite(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/update_account_maxinvite.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiUpdateMaxDate(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/update_account_maxdate.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiUpdateNormalAccount(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/update_account_normal.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiUpdateAccountActive(manage_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/update_account_active.php",
      data: { manage_id: manage_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGroupAccountVpsList() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/group_account_vps_list.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGroupAccountVpsDelete(code_vps) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/group_account_vps_delete.php",
      data: { code_vps: code_vps },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGroupAccountVpsDeleteAll(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/group_account_vps_delete_all.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGroupAccountVpsUpdate(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/group_account_vps_update.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiDashboardVps() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/dashboard_vps.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAccountUpdateAlias(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/accountMt4_reupdate.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiTelegramAccountGroupList() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/telegram_account_group_list.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiTelegramAccountGroupInsert(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/telegram_account_group_insert.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAccountOption() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/accountMt4_option.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiDailyBalanceDate(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/daily_balance_date.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiDailyBalance() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/daily_balance.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGetDataContact() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/contact_list.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAllContactDeleteAll(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/contact_delete_all.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAllContactDelete(contact_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/contact_delete.php",
      data: { contact_id: contact_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiContactInsertUpdate(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/contact_insert_update.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiImportContact(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/import_contact.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGetTemplate() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/template_list.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiTemplateDeleteAll(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/template_delete_all.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiTemplateDelete(template_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/template_delete.php",
      data: { template_id: template_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiTemplateInsertUpdate(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/template_insert_update.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiItemPrizeAdd(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "/prize_item_add.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}


