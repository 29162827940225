import React, { Component } from "react";
import {
  Typography,
  Button,
  Grid,
  Box,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Slide,
  Stack,
  Alert,
} from "@mui/material";
import { ArrowBackIos, Save, Cancel, Close } from "@mui/icons-material";
import { Input } from "reactstrap";
import {
  apiWebsiteInsertUpdate,
  apiPrizeCreateBukaan,
} from "../../Service/api";
import { uuidRandomId, generateData } from "../../../global";
import moment from "moment";
import Select from "react-select";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class InputWebsitePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agentId: "",
      projectName: "",
      projectUrl: "",
      setTime: "",
      digitBukaan: 0,
      totalPrize: 0,
      openValidation: false,
      titleValidation: "",
      titleBody: "",
      nomorPrize: "",
      prizeList: [],
      showLoadingSubmit: false,
      openSuccess: false,
      openSuccessText: "",
      optionStatusRunning: [
        { value: 0, label: "STOP" },
        { value: 1, label: "RUNNING" },
      ],
      selectedStatusRunning: null,
    };
  }

  componentDidMount = () => {};

  doSubmit = () => {
    const {
      projectName,
      projectUrl,
      digitBukaan,
      totalPrize,
      selectedStatusRunning,
    } = this.state;
    if (projectName === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Nama project kosong?",
        titleBody: "Silahkan isi nama project",
      });
    } else if (projectUrl === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Url Project kosong?",
        titleBody: "Silahkan isi url project",
      });
    } else if (digitBukaan === 0) {
      this.setState({
        openValidation: true,
        titleValidation: "Digit bukaan 0?",
        titleBody: "Digit bukaan tidak boleh 0 (kosong)",
      });
    } else if (totalPrize === 0) {
      this.setState({
        openValidation: true,
        titleValidation: "Total prize 0?",
        titleBody: "Total prize tidak boleh 0 (kosong)",
      });
    } else if (selectedStatusRunning === null) {
      this.setState({
        openValidation: true,
        titleValidation: "pilih status kosong?",
        titleBody: "Silahkan pilih status",
      });
    } else {
      let params = {
        agentId: uuidRandomId(),
        projectName: this.state.projectName,
        projectUrl: this.state.projectUrl,
        setTime: this.state.setTime,
        digitBukaan: this.state.digitBukaan,
        totalPrize: this.state.totalPrize,
        statusRunning:
          selectedStatusRunning === null ? 0 : selectedStatusRunning.value,
      };
      // console.log(params);
      this.props.doLoading();
      this.setState({ showLoadingSubmit: true });
      apiWebsiteInsertUpdate(params)
        .then((res) => {
          // console.log(res);
          this.props.doLoading();
          // this.doCreatePrize(params);
          this.setState({
            showLoadingSubmit: false,
            openSuccess: true,
            openSuccessText: "Data berhasil disimpan",
          });
        })
        .catch((err) => {
          console.log(err);
          this.props.doLoading();
          this.setState({ showLoadingSubmit: false });
        });
    }
  };

  doCreatePrize = (params) => {
    let currentDate = new Date();
    var addDate = moment(currentDate).format("YYYY-MM-DD");

    let requestPrize = {
      id_agent: params.agentId,
      project_name: params.projectName,
      set_jam: params.setTime,
      digit_bukaan: params.digitBukaan,
      total_prize: params.totalPrize,
      date_active: addDate,
      infoBukaan: generateData(
        parseInt(params.totalPrize),
        parseInt(params.digitBukaan)
      ),
    };
    apiPrizeCreateBukaan(requestPrize)
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleCloseValidationAlert = () => {
    this.setState({
      openValidation: false,
      titleValidation: "",
      titleBody: "",
    });
  };

  renderValidationAlert = () => {
    return (
      <Dialog
        open={this.state.openValidation}
        onClose={this.handleCloseValidationAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        fullWidth="xs"
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="label-title-alert">{this.state.titleValidation}</div>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <div className="title-body-alert"> {this.state.titleBody} </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton
            style={{ backgroundColor: "#f2f2f2" }}
            onClick={this.handleCloseValidationAlert}
          >
            <Close />
          </IconButton>
        </DialogActions>
      </Dialog>
    );
  };

  closeStackSuccess = () => {
    this.setState({
      openSuccess: false,
      openSuccessText: "",
    });
    this.props.history.push("/panel/website");
  };

  renderSuccess = () => {
    if (this.state.openSuccess === true) {
      setTimeout(() => this.closeStackSuccess(), 2000);

      return (
        <div style={{ margin: 10 }}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert
              variant="filled"
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  // onClick={() => this.reset()}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {this.state.openSuccessText}
            </Alert>
          </Stack>
        </div>
      );
    }
  };

  handleChangeStatusRunning = (selectedStatusRunning) => {
    this.setState({
      selectedStatusRunning: selectedStatusRunning,
    });
  };

  render() {
    return (
      <div>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper
                style={{
                  borderLeft: 6,
                  borderColor: "#cccccc",
                  backgroundColor: "#cccccc",
                  maxHeight: 100,
                  padding: 16,
                }}
              >
                <div className="page-header">
                  <Button
                    variant="contained"
                    size="small"
                    style={{
                      backgroundColor: "#000",
                    }}
                    startIcon={<ArrowBackIos />}
                    onClick={() => this.props.history.push("/panel/website")}
                  >
                    <Typography
                      variant="button"
                      style={{
                        fontSize: 12,
                        color: "#fff",
                        textTransform: "capitalize",
                        marginLeft: -10,
                      }}
                    >
                      Kembali
                    </Typography>
                  </Button>{" "}
                  <Typography
                    component="span"
                    variant="h2"
                    style={{
                      fontSize: 16,
                      color: "#000",
                      fontWeight: "bold",
                      textTransform: "capitalize",
                      float: "right",
                    }}
                  >
                    Tambah Website
                  </Typography>
                  <span className="dash">&nbsp;&nbsp;</span>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <br></br>
        <div className="box-container">
          <Box sx={{ flexGrow: 1 }}>
            <div className="">
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Nama Project
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="projectName"
                    id="projectName"
                    value={this.state.projectName}
                    onChange={(event) =>
                      this.setState({ projectName: event.target.value })
                    }
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Url Project
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="projectUrl"
                    id="projectUrl"
                    value={this.state.projectUrl}
                    onChange={(event) =>
                      this.setState({ projectUrl: event.target.value })
                    }
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Digit Bukaan
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Input
                    autoComplete="off"
                    type="number"
                    name="digitBukaan"
                    id="digitBukaan"
                    value={this.state.digitBukaan}
                    onChange={(event) =>
                      this.setState({ digitBukaan: event.target.value })
                    }
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Total Prize
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Input
                    autoComplete="off"
                    type="number"
                    name="totalPrize"
                    id="totalPrize"
                    value={this.state.totalPrize}
                    onChange={(event) =>
                      this.setState({ totalPrize: event.target.value })
                    }
                  />
                </Grid>

                {/* <Grid item xs={2}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Set Waktu
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Input
                    autoComplete="off"
                    type="time"
                    name="setTime"
                    id="setTime"
                    value={this.state.setTime}
                    onChange={(event) =>
                      this.setState({ setTime: event.target.value })
                    }
                  />
                </Grid> */}
                <Grid item xs={2}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Status
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Select
                    // isClearable
                    classNamePrefix="select"
                    placeholder="Pilih Status"
                    value={this.state.selectedStatusRunning}
                    onChange={this.handleChangeStatusRunning}
                    options={this.state.optionStatusRunning}
                  />
                </Grid>
              </Grid>
            </div>
          </Box>
          <br></br>
          <div className="form-button-container">
            <br></br>
            <Button
              variant="contained"
              size="medium"
              style={{
                backgroundColor: "#d0021b",
              }}
              startIcon={<Cancel />}
              onClick={() => this.props.history.push("/panel/website")}
            >
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                  marginLeft: -6,
                }}
              >
                Batal
              </Typography>
            </Button>{" "}
            &nbsp;&nbsp;
            <Button
              variant="contained"
              size="medium"
              style={{
                backgroundColor: "#004dcf",
              }}
              startIcon={<Save />}
              onClick={() => this.doSubmit()}
            >
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                  marginLeft: -6,
                }}
              >
                Simpan
              </Typography>
            </Button>{" "}
          </div>
        </div>
        <br></br>
        {this.renderValidationAlert()}
        {this.renderSuccess()}
      </div>
    );
  }
}
export default InputWebsitePage;
