import React, { Component } from "react";
import {
  Typography,
  Button,
  Grid,
  Box,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Slide,
  Stack,
  Alert,
  AppBar,
  Toolbar,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Pagination,
} from "@mui/material";
import {
  ArrowBackIos,
  Save,
  Cancel,
  Close,
  Edit,
  EightMp,
  Check,
  AddBox,
  Delete,
  ExpandMore,
  // TryRounded,
} from "@mui/icons-material";
import { Input } from "reactstrap";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {
  apiWebsiteUpdate,
  apiWebsiteGetId,
  // apiWebsiteBukaanAgent,
  apiPrizeCreateBukaan,
  apiPrizeUpdateBukaan,
  apiPrizeDeleteBukaan,
  apiLogActivityInsert,
  apiPrizeCreateMultiBukaan,
  apiWebsiteBukaanAgentIndex,
  apiItemPrizeAdd,
  apiWebsiteBukaanAgent,
} from "../../Service/api";
import { generateDataRandom } from "../../../global";
import moment from "moment";
import Select from "react-select";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const stylesListDialog = {
  inline: {
    display: "inline",
  },
};

const stylesDialog = {
  appBar: {
    position: "relative",
    backgroundColor: "#03101e",
  },
  title: {
    marginLeft: 0,
    flex: 1,
    fontSize: 16,
  },
};

class EditWebsitePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agentId: props.match.params.id_agent,
      projectName: "",
      projectUrl: "",
      setTime: "",
      setTimePrize: "",
      digitBukaan: 0,
      totalPrize: 0,
      openValidation: false,
      titleValidation: "",
      titleBody: "",
      nomorPrize: "",
      prizeList: [],
      showLoadingSubmit: false,
      submitLoading: false,
      openSuccess: false,
      openSuccessText: "",
      infoBukaanAgent: [],
      loginInfo: "",
      openAlertBukaan: false,
      openDialogForm: false,
      dataInfoBukaan: [],
      dataInfoBukaan_s: "",
      dataInfoBukaan_n: [{ nomor: "", time: "" }],
      dataInfoPrize_n: [
        {
          nomor: "",
          timeActive: "",
          dateActive: "",
          isAuto: 0,
        },
      ],
      infoPrize: [
        {
          nomor: "",
          timeActive: "",
          dateActive: "",
          isAuto: 0,
          status: 0,
        },
      ],
      optionStatus: [
        { status: 0, label: "OPEN" },
        { status: 1, label: "CLOSE" },
      ],
      status: null,
      status_s: 0,
      setOpenAddMultiBukaan: false,
      openAlertDelete: false,
      itemDeleted: "",
      openAlertDeleteAll: false,
      selectRowData: [],
      newSetTime: "",
      digitPrize: 0,

      totalListPrize: 0,
      newDatePrize: new Date().toISOString().split("T")[0],
      optionStatusRunning: [
        { value: 0, label: "STOP" },
        { value: 1, label: "RUNNING" },
      ],
      selectedStatusRunning: null,
      accordionInfoWebsite: "Info-Website",
      accordionPrizeList: "Prize-List",
      dateEdit: "",
      pages: 1,
      pageIdx: 0,
      pageSize: 5,
      pagesCount: 0,
    };
  }

  componentDidMount = () => {
    this.doGetDetail(this.state.agentId);
    this.getRoleData();
  };

  getRoleData = () => {
    let loginInfo = localStorage.getItem("loginInfo");
    loginInfo = JSON.parse(loginInfo);
    this.setState({
      loginInfo: loginInfo,
    });
  };

  doGetDetail = (agentId) => {
    this.props.doLoading();
    apiWebsiteGetId(agentId)
      .then((res) => {
        // console.log(res);
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          let status = this.state.optionStatusRunning.filter(
            (obj) => obj.value === data.record.status
          );

          this.setState({
            agentId: data.record.id_agent,
            projectName: data.record.project_name,
            projectUrl: data.record.url,
            setTime: data.record.set_jam,
            digitBukaan: data.record.digit_bukaan,
            totalPrize: data.record.total_prize,
            selectedStatusRunning: status[0],
          });
        }

        if (this.state.pageIdx === 0) {
          this.doGetBukaanAgent(
            data.record.id_agent,
            this.state.pageIdx,
            this.state.pageSize
          );
        } else {
          this.doPagingIdx(this.state.pageIdx);
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  doGetBukaanAgent = (agentId, idx, size) => {
    apiWebsiteBukaanAgent(agentId)
      .then((res) => {
        // console.log(res);
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            const dataInfo = data.records;
            this.setState({
              infoBukaanAgent: dataInfo,
            });
          } else {
            this.setState({
              infoBukaanAgent: [],
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });

    apiWebsiteBukaanAgentIndex(agentId, idx, size)
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            let dataInfo = data.records;
            for (var i = 0; i < dataInfo.length; i++) {
              if (dataInfo[i].infoBukaan) {
                dataInfo[i].prizeList = dataInfo[i].infoBukaan.reduce(function (
                  a,
                  b
                ) {
                  return (a.nomor || a) + ", " + b.nomor;
                });
              } else {
                // Handle the case where infoBukaan is undefined or empty
                dataInfo[i].prizeList = ""; // or any other appropriate value
              }
            }
            let pagesData = Math.ceil(data.count / this.state.pageSize);
            this.setState({
              infoBukaanAgent: dataInfo,
              pagesCount: pagesData,
            });
          } else {
            this.setState({
              infoBukaanAgent: [],
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doPagingIdx = (pagesIndex) => {
    // this.props.doLoading();
    apiWebsiteBukaanAgentIndex(
      this.state.agentId,
      pagesIndex,
      this.state.pageSize
    )
      .then((res) => {
        // this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            var list = [];
            let temp = data.records;

            const results = list.filter(
              ({ date_active: id1, time_active: ida1 }) =>
                temp.some(
                  ({ date_active: id2, time_active: ida2 }) =>
                    id2 === id1 && ida2 === ida1
                )
            );

            // console.log("results: ", results);

            if (results.length === 0) {
              for (var i = 0; i < temp.length; i++) {
                list.push(temp[i]);
              }

              let pagesData = Math.ceil(data.count / this.state.pageSize);
              this.setState({ infoBukaanAgent: list, pagesCount: pagesData });
            }
          }
        }
      })
      .catch((err) => {
        // this.props.doLoading();
        console.log(err);
      });
  };

  doUpdateBukaanAgent = (params) => {
    this.setState({ submitLoading: true });
    apiPrizeUpdateBukaan(params)
      .then((res) => {
        // console.log(res);
        this.setState({ submitLoading: false });
        let data = res.data;
        if (data.status === "OK") {
          this.doGetDetail(this.state.agentId);
          // this.handleCloseDialogForm();

          this.setState({
            openDialogForm: false,
          });

          let prizeNomor = [];
          params.infoPrize.map((item) => {
            prizeNomor.push(item.nomor);
          });
          prizeNomor.join(", ");

          let paramsLog = {
            userId: this.state.loginInfo.id_user,
            description:
              "Edit nomor prize bukaan menjadi " +
              prizeNomor.join(", ") +
              " untuk project " +
              this.state.projectName,
          };

          this.doLogActivityInsert(paramsLog);
        }
      })
      .catch((err) => {
        this.setState({ submitLoading: false });
        console.log(err);
      });
  };

  doDeletePrizeBukaan = () => {
    let item = this.state.itemDeleted;

    let params = {
      agentId: this.state.agentId,
      prizeId: item.id_prize,
    };
    // console.log(params);
    this.setState({ showLoadingSubmit: true });
    apiPrizeDeleteBukaan(params)
      .then((res) => {
        // console.log(res);

        this.setState({ showLoadingSubmit: false });

        let data = res.data;
        if (data.status === "OK") {
          this.doGetDetail(this.state.agentId);
          this.handleCloseItemInfo();

          // console.log("asdasd", item);

          let prizeNomor = [];
          item.infoPrize.map((item) => {
            prizeNomor.push(item.nomor);
          });
          prizeNomor.join(", ");

          let paramsLog = {
            userId: this.state.loginInfo.id_user,
            description:
              "Hapus nomor prize bukaan " +
              prizeNomor.join(", ") +
              " untuk project " +
              this.state.projectName,
          };

          this.doLogActivityInsert(paramsLog);
        }
      })
      .catch((err) => {
        this.setState({ showLoadingSubmit: false });
        console.log(err);
      });
  };

  doSubmit = () => {
    const {
      projectName,
      projectUrl,
      setTime,
      digitBukaan,
      totalPrize,
      selectedStatusRunning,
    } = this.state;
    if (projectName === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Nama project kosong?",
        titleBody: "Silahkan isi nama project",
      });
    } else if (projectUrl === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Url Project kosong?",
        titleBody: "Silahkan isi url project",
      });
    } else if (digitBukaan === 0) {
      this.setState({
        openValidation: true,
        titleValidation: "Digit bukaan 0?",
        titleBody: "Digit bukaan tidak boleh 0 (kosong)",
      });
    } else if (totalPrize === 0) {
      this.setState({
        openValidation: true,
        titleValidation: "Total prize 0?",
        titleBody: "Total prize tidak boleh 0 (kosong)",
      });
    } else if (selectedStatusRunning === null) {
      this.setState({
        openValidation: true,
        titleValidation: "pilih status kosong?",
        titleBody: "Silahkan pilih status",
      });
    } else {
      let params = {
        agentId: this.state.agentId,
        projectName: this.state.projectName,
        projectUrl: this.state.projectUrl,
        setTime: this.state.setTime,
        digitBukaan: this.state.digitBukaan,
        statusRunning:
          selectedStatusRunning === null ? 0 : selectedStatusRunning.value,
        totalPrize: this.state.totalPrize,
      };
      // console.log(params);
      this.props.doLoading();
      this.setState({ showLoadingSubmit: true });
      apiWebsiteUpdate(params)
        .then((res) => {
          // console.log(res);
          this.props.doLoading();
          this.setState({
            showLoadingSubmit: false,
            openSuccess: true,
            openSuccessText: "Data berhasul disimpan",
          });

          let paramsLog = {
            userId: this.state.loginInfo.id_user,
            description:
              "Update data project/website " + this.state.projectName,
          };

          this.doLogActivityInsert(paramsLog);
        })
        .catch((err) => {
          console.log(err);
          this.props.doLoading();
          this.setState({ showLoadingSubmit: false });
        });
    }
  };

  doLogActivityInsert = (params) => {
    apiLogActivityInsert(params)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  pressDoGenerate = () => {
    let currentDate = new Date();
    var addDate = moment(currentDate).format("YYYY-MM-DD");

    let requestPrize = {
      id_agent: this.state.agentId,
      project_name: this.state.projectName,
      set_jam: this.state.setTime,
      digit_bukaan: this.state.digitBukaan,
      total_prize: this.state.totalPrize,
      date_active: addDate,
      infoBukaan: generateDataRandom(
        parseInt(this.state.totalPrize),
        parseInt(this.state.digitBukaan)
      ),
    };
    this.setState({
      showLoadingSubmit: true,
    });
    apiPrizeCreateBukaan(requestPrize)
      .then((res) => {
        this.setState({
          showLoadingSubmit: false,
        });
        // console.log(res);
        let data = res.data;
        if (data.status === "Exists") {
          this.setState({
            openValidation: true,
            titleValidation: "Alert",
            titleBody: "Prize bukaan sudah ada untuk tanggal " + addDate,
            openAlertBukaan: false,
          });
        } else {
          this.setState({
            openAlertBukaan: false,
            openValidation: true,
            titleValidation: "Alert",
            titleBody:
              "Prize bukaan sudah di generate untuk tanggal " + addDate,
          });

          let paramsLog = {
            userId: this.state.loginInfo.id_user,
            description:
              "Generate nomor prize bukaan tanggal " +
              addDate +
              " untuk project " +
              this.state.projectName,
          };

          this.doLogActivityInsert(paramsLog);

          this.doGetDetail(this.state.agentId);
        }
      })
      .catch((err) => {
        this.setState({
          showLoadingSubmit: false,
        });
        console.log(err);
      });
  };

  handleCloseValidationAlert = () => {
    this.setState({
      openValidation: false,
      titleValidation: "",
      titleBody: "",
    });
  };

  renderValidationAlert = () => {
    return (
      <Dialog
        open={this.state.openValidation}
        onClose={this.handleCloseValidationAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        fullWidth="xs"
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="label-title-alert">{this.state.titleValidation}</div>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <div className="title-body-alert"> {this.state.titleBody} </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton
            style={{ backgroundColor: "#f2f2f2" }}
            onClick={this.handleCloseValidationAlert}
          >
            <Close />
          </IconButton>
        </DialogActions>
      </Dialog>
    );
  };

  closeStackSuccess = () => {
    this.setState({
      openSuccess: false,
      openSuccessText: "",
    });
    this.props.history.push("/panel/website");
  };

  renderSuccess = () => {
    if (this.state.openSuccess === true) {
      setTimeout(() => this.closeStackSuccess(), 2000);

      return (
        <div style={{ margin: 10 }}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert
              variant="filled"
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  // onClick={() => this.reset()}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {this.state.openSuccessText}
            </Alert>
          </Stack>
        </div>
      );
    }
  };

  doRowEdit = (row) => {
    this.setState({
      openDialogForm: true,
      dateEdit: row.date,
      infoPrize: row.infoPrize,
    });
  };

  doRowDeleteInfo = (item) => {
    this.setState({
      openAlertDelete: true,
      itemDeleted: item,
    });
  };

  handleCloseItemInfo = () => {
    this.setState({
      openAlertDelete: false,
      itemDeleted: "",
    });
  };

  renderRemoveItemInfo = () => {
    let item = this.state.itemDeleted;
    if (item !== "") {
      let output = [];
      item.infoPrize.map((item) => {
        output.push(item.nomor);
      });

      return (
        <Dialog
          open={this.state.openAlertDelete}
          onClose={this.handleCloseItemInfo}
          aria-labelledby="customized-dialog-title"
          fullWidth="sm"
          maxWidth="sm"
        >
          <AppBar style={stylesDialog.appBar}>
            <Toolbar>
              <Typography
                component="span"
                variant="h2"
                style={stylesDialog.title}
              >
                Hapus Nomor Prize Bukaan?
              </Typography>
              <IconButton
                edge="end"
                color="inherit"
                onClick={() => this.handleCloseItemInfo()}
                aria-label="close"
              >
                <Close />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent style={{ marginTop: 10 }}>
            <DialogContentText id="alert-dialog-description">
              <Typography
                component="span"
                variant="body2"
                style={
                  (stylesListDialog.inline, { fontSize: 16, color: "#333" })
                }
              >
                Hapus Nomor Prize Bukaan {output.join(", ")}?
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#808080",
              }}
              startIcon={<Cancel />}
              onClick={this.handleCloseItemInfo}
            >
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Batal
              </Typography>
            </Button>{" "}
            <Button
              disabled={this.state.showLoadingSubmit}
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#004dcf",
              }}
              startIcon={
                this.state.showLoadingSubmit === true ? null : <Check />
              }
              onClick={() => this.doDeletePrizeBukaan()}
            >
              {this.state.showLoadingSubmit === true ? (
                <CircularProgress style={{ color: "#fff" }} size={24} />
              ) : (
                <Typography
                  variant="button"
                  style={{
                    color: "#fff",
                    textTransform: "capitalize",
                  }}
                >
                  Ya
                </Typography>
              )}
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
  };

  handleUpdate = (pageIndex) => {
    this.setState({ pageIdx: pageIndex, pages: pageIndex + 1 });
    this.doPagingIdx(pageIndex);
  };

  getNumberWithSuffix = (number) => {
    const suffixes = ["st", "nd", "rd"];
    const lastDigit = number % 10;
    const suffix =
      lastDigit >= 1 && lastDigit <= 3 ? suffixes[lastDigit - 1] : "th";
    return `${number}${suffix}`;
  };

  renderTable = () => {
    const { pageIdx, pagesCount, pageSize } = this.state;
    if (this.state.infoBukaanAgent.length > 0) {
      return (
        <>
          <ReactTable
            ref={(r) => (this.reactTable = r)}
            data={this.state.infoBukaanAgent}
            manual
            pages={pagesCount}
            pageSize={pageSize}
            page={pageIdx}
            onPageChange={this.handleUpdate}
            showPagination={true}
            showPageJump={false}
            showPageSizeOptions={false}
            columns={[
              {
                Header: "Date",
                headerStyle: { fontWeight: "bold" },
                accessor: "date",
                style: {
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                },
              },
              {
                Header: "Info Prize",
                columns: [
                  {
                    Header: "Prize",
                    headerStyle: { fontWeight: "bold" },
                    accessor: "infoPrize",
                    style: { textAlign: "center" },
                    Cell: (e) => (
                      <ul style={{ listStyleType: "none", padding: 0 }}>
                        {e.original.infoPrize.map((prize, index) => (
                          <li
                            style={{
                              fontSize: "16px",

                              marginTop: 5,
                            }}
                            key={index}
                          >
                            {this.getNumberWithSuffix(prize.sequence)}
                          </li>
                        ))}
                      </ul>
                    ),
                  },
                  {
                    Header: "Number",
                    headerStyle: { fontWeight: "bold" },
                    accessor: "infoPrize",
                    style: { textAlign: "center" },
                    Cell: (e) => (
                      <ul style={{ listStyleType: "none", padding: 0 }}>
                        {e.original.infoPrize.map((prize, index) => (
                          <li
                            style={{
                              fontSize: "16px",

                              marginTop: 5,
                            }}
                            key={index}
                          >
                            {prize.nomor}
                          </li>
                        ))}
                      </ul>
                    ),
                  },
                  {
                    Header: "Time",
                    headerStyle: { fontWeight: "bold" },
                    accessor: "infoPrize",
                    style: { textAlign: "center" },
                    Cell: (e) => (
                      <ul style={{ listStyleType: "none", padding: 0 }}>
                        {e.original.infoPrize.map((prize, index) => (
                          <li
                            style={{
                              fontSize: "16px",

                              marginTop: 5,
                            }}
                            key={index}
                          >
                            {prize.time_active}
                          </li>
                        ))}
                      </ul>
                    ),
                  },
                  {
                    Header: "Status",
                    headerStyle: { fontWeight: "bold" },
                    accessor: "infoPrize",
                    style: { textAlign: "center" },
                    Cell: (e) => (
                      <ul style={{ listStyleType: "none", padding: 0 }}>
                        {e.original.infoPrize.map((prize, index) => (
                          <li
                            style={{
                              fontSize: "16px",
                              fontWeight: "600",
                              marginTop: 5,
                              color: prize.status === 1 ? "red" : "green",
                            }}
                            key={index}
                          >
                            {prize.status === 1 ? "CLOSE" : "OPEN"}
                          </li>
                        ))}
                      </ul>
                    ),
                  },
                ],
              },
              {
                Header: "Action",
                headerStyle: { fontWeight: "bold" },
                accessor: "",
                style: {
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                },
                width: 200,
                Cell: (e) =>
                  this.state.loginInfo.logintype === 2 ? (
                    <>
                      <div>
                        <Button
                          variant="contained"
                          size="small"
                          style={{
                            backgroundColor: "#3f51b5",
                          }}
                          startIcon={<Edit />}
                          onClick={() => this.doRowEdit(e.original)}
                        >
                          <Typography
                            variant="button"
                            style={{
                              fontSize: 14,
                              color: "#fff",
                              textTransform: "capitalize",
                            }}
                          >
                            Edit
                          </Typography>
                        </Button>
                        &nbsp;
                        <Button
                          variant="contained"
                          size="small"
                          style={{
                            backgroundColor: "#ff0000",
                          }}
                          startIcon={<Delete />}
                          onClick={() => this.doRowDeleteInfo(e.original)}
                        >
                          <Typography
                            variant="button"
                            style={{
                              fontSize: 14,
                              color: "#fff",
                              textTransform: "capitalize",
                            }}
                          >
                            Hapus
                          </Typography>
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <Button
                          variant="contained"
                          size="small"
                          style={{
                            backgroundColor: "#3f51b5",
                          }}
                          startIcon={<Edit />}
                          onClick={() => this.doRowEdit(e.original)}
                        >
                          <Typography
                            variant="button"
                            style={{
                              fontSize: 14,
                              color: "#fff",
                              textTransform: "capitalize",
                            }}
                          >
                            Edit
                          </Typography>
                        </Button>
                        &nbsp;
                        <Button
                          variant="contained"
                          size="small"
                          style={{
                            backgroundColor: "#ff0000",
                          }}
                          startIcon={<Delete />}
                          onClick={() => this.doRowDeleteInfo(e.original)}
                        >
                          <Typography
                            variant="button"
                            style={{
                              fontSize: 14,
                              color: "#fff",
                              textTransform: "capitalize",
                            }}
                          >
                            Hapus
                          </Typography>
                        </Button>
                      </div>
                    </>
                  ),
              },
            ]}
            style={{ backgroundColor: "#f2f2f2" }}
          />
        </>
      );
    }
  };

  doGenerateBukaan = () => {
    this.setState({
      openAlertBukaan: true,
    });
  };

  handleCloseAlertGenerate = () => {
    this.setState({
      openAlertBukaan: false,
    });
  };

  renderGenerateAlert = () => {
    return (
      <Dialog
        open={this.state.openAlertBukaan}
        onClose={this.handleCloseAlertGenerate}
        aria-labelledby="customized-dialog-title"
        fullWidth="sm"
        maxWidth="sm"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography
              component="span"
              variant="h2"
              style={stylesDialog.title}
            >
              Generate Prize?
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseAlertGenerate()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ marginTop: 10 }}>
          <DialogContentText id="alert-dialog-description">
            <Typography
              component="span"
              variant="body2"
              style={(stylesListDialog.inline, { fontSize: 16, color: "#333" })}
            >
              Generate Prize Bukaan untuk website {this.state.projectName}?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#808080",
            }}
            startIcon={<Cancel />}
            onClick={this.handleCloseAlertGenerate}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Batal
            </Typography>
          </Button>{" "}
          <Button
            disabled={this.state.showLoadingSubmit}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#004dcf",
            }}
            startIcon={this.state.showLoadingSubmit === true ? null : <Check />}
            onClick={() => this.pressDoGenerate()}
          >
            {this.state.showLoadingSubmit === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Ya
              </Typography>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  handleCloseDialogForm = () => {
    // console.log("dataInfoBukaan_s: ", this.state.dataInfoBukaan_s);
    // console.log("info: ", this.state.infoBukaanAgent);

    let dataBefore = this.state.dataInfoBukaan_s.split(", ");
    let array = this.state.dataInfoBukaan;
    for (var i = 0; i < dataBefore.length; i++) {
      array[i].nomor = dataBefore[i];
    }

    this.setState({
      dataInfoBukaan: array,
    });

    this.setState({
      openDialogForm: false,
      // dataInfoBukaan: this.state.dataInfoBukaan_s,
    });
    // this.doGetDetail(this.state.agentId);
  };

  handleCloseDialogFormEdit = () => {
    this.doGetDetail(this.state.agentId);
    this.setState({
      openDialogForm: false,
    });
  };

  changeNomorBukaan = (newValue, index) => {
    // console.log(newValue);
    let array = this.state.dataInfoBukaan;
    array[index].nomor = newValue;

    // console.log("array: ", array);

    this.setState({
      dataInfoBukaan: array,
    });
  };

  changeNewNomorPrize = (newValue, index) => {
    let array = this.state.dataInfoPrize_n;
    array[index].nomor = newValue;
    this.setState({
      dataInfoPrize_n: array,
    });
  };

  changeNewTimePrize = (newTime, index) => {
    let array = this.state.dataInfoPrize_n;
    const isTimeAlreadyExists = array.some(
      (item, i) => i !== index && item.timeActive === newTime
    );

    if (isTimeAlreadyExists) {
      // Handle the case when the newTime already exists, e.g., show an error message
      this.setState({
        openValidation: true,
        titleValidation: "Alert",
        titleBody: "Set Time Tidak Boleh Sama",
      });
    } else {
      array[index].timeActive = newTime;
      array[index].isAuto = 1;
      array[index].dateActive = this.state.newDatePrize;

      this.setState({
        dataInfoPrize_n: array,
      });
    }
  };

  handleTimeChange = (event, index) => {
    const updatedInfoPrize = [...this.state.infoPrize];
    updatedInfoPrize[index].time_active = event.target.value;
    this.setState({ infoPrize: updatedInfoPrize });
  };
  handleNomorChange = (event, index) => {
    const updatedInfoPrize = [...this.state.infoPrize];
    updatedInfoPrize[index].nomor = event.target.value;
    this.setState({ infoPrize: updatedInfoPrize });
  };

  changeNewInfoPrize = (newValue, newTime, index) => {
    // Create a copy of the dataInfoPrize_n array
    let array = [...this.state.dataInfoPrize_n];

    // Update the "nomor" and "time" properties for the specified index
    array[index].nomor = newValue;
    array[index].time = newTime;

    // Update the state with the modified array
    this.setState({
      dataInfoPrize_n: array,
    });
  };

  checkData = () => {
    let params = {
      agentId: this.state.agentId,
      infoPrize: this.state.infoPrize,
    };
    // console.log(params);
    this.doUpdateBukaanAgent(params);
  };

  checkDataPrize = () => {
    var wasNullPrize = false;
    for (let i = 0; i < this.state.dataInfoPrize_n.length; i++) {
      var nomor = this.state.dataInfoPrize_n[i].nomor.toString();
      var time = this.state.dataInfoPrize_n[i].timeActive;
      // console.log("time", time)

      if (nomor.length !== parseInt(this.state.digitBukaan)) {
        wasNullPrize = true;
        this.setState({
          openValidation: true,
          titleValidation: "Digit Prize belum sesuai?",
          titleBody:
            "Total digit prize harus " + this.state.digitBukaan + " digit.",
        });
      } else if (!time || time.trim() === "") {
        // Handle the case when time is empty or undefined
        wasNullPrize = true;
        this.setState({
          openValidation: true,
          titleValidation: "Time prize belum diisi?",
          titleBody: "Waktu harus diisi.",
        });
      }
    }

    if (!wasNullPrize) {
      // console.log("berhasil");
      this.state.dataInfoPrize_n.forEach((item) => {
        item.dateActive = this.state.newDatePrize;
      });
      var date_active = this.state.newDatePrize;
      let params = {
        id_agent: this.state.agentId,
        date_active: this.state.newDatePrize,
        infoPrize: this.state.dataInfoPrize_n,
      };
      this.setState({
        showLoadingSubmit: true,
      });

      console.log(params);

      apiItemPrizeAdd(params)
        .then((res) => {
          this.setState({
            showLoadingSubmit: false,
          });
          // console.log(res);
          let data = res.data;
          if (data.status === "Exists") {
            this.setState({
              openValidation: true,
              titleValidation: "Alert",
              titleBody: "Prize bukaan sudah ada untuk tanggal " + date_active,
              openAlertBukaan: false,
            });
          } else {
            this.setState({
              setOpenAddMultiBukaan: false,
              openAlertBukaan: false,
              openValidation: true,
              titleValidation: "Alert",
              titleBody:
                "Prize bukaan sudah di generate untuk tanggal " + date_active,
            });
            this.doGetDetail(this.state.agentId);
          }
        })
        .catch((err) => {
          this.setState({
            showLoadingSubmit: false,
          });
          console.log(err);
        });
    }
  };

  handleChangeStatus = (selectedOption, index) => {
    // Dapatkan indeks atau item yang sesuai dalam array dataInfoPrize_n
    const { infoPrize } = this.state;
    const selectedItem = infoPrize[index];

    // Perbarui status di item yang sesuai
    selectedItem.status = selectedOption.value;

    // Perbarui state dengan perubahan tersebut
    this.setState({ infoPrize });
  };

  handleChangeStatusRunning = (selectedStatusRunning) => {
    this.setState({
      selectedStatusRunning: selectedStatusRunning,
    });
  };

  renderDialogForm = () => {
    const optionStatus = [
      { value: 0, label: "OPEN" },
      { value: 1, label: "CLOSE" },
    ];
    return (
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={this.state.openDialogForm}
        fullWidth="md"
        maxWidth="md"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography variant="h5" style={stylesDialog.title}>
              Edit Prize Number
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseDialogFormEdit()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <Box sx={{ flexGrow: 1 }} style={{ marginBottom: 60, marginTop: 20 }}>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    color: "#000",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                >
                  Tanggal
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Input
                  autoComplete="off"
                  type="date"
                  name="dateEdit"
                  id="dateEdit"
                  value={this.state.dateEdit}
                  onChange={(event) => {
                    this.setState({ dateEdit: event.target.value });
                  }}
                />
              </Grid>
              {this.state.infoPrize.map((item, i) => (
                <>
                  <Grid item xs={1.5}>
                    <Typography
                      component="span"
                      variant="subtitle1"
                      style={{
                        float: "left",
                        marginTop: 6,
                        color: "#000",
                        fontWeight: "bold",
                        textTransform: "capitalize",
                      }}
                    >
                      {i + 1}st Prize
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Input
                      autoComplete="off"
                      type="text"
                      name="nomor"
                      id="nomor"
                      maxLength={parseInt(this.state.digitBukaan)}
                      value={item.nomor}
                      onChange={(event) => this.handleNomorChange(event, i)}
                    />
                  </Grid>
                  <Grid item xs={1.5}>
                    <Typography
                      component="span"
                      variant="subtitle1"
                      style={{
                        float: "left",
                        marginTop: 6,
                        color: "#000",
                        fontWeight: "bold",
                        textTransform: "capitalize",
                      }}
                    >
                      Set Time
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Input
                      autoComplete="off"
                      type="time"
                      name="setTimePrize"
                      id="setTimePrize"
                      value={item.time_active}
                      onChange={(event) => this.handleTimeChange(event, i)}
                    />
                  </Grid>

                  <Grid item xs={1.5}>
                    <Typography
                      component="span"
                      variant="subtitle1"
                      style={{
                        float: "left",
                        marginTop: 6,
                        color: "#000",
                        fontWeight: "bold",
                        textTransform: "capitalize",
                      }}
                    >
                      Status
                    </Typography>
                  </Grid>
                  {item.status === 0 ? (
                    <Grid item xs={2.5}>
                      <Select
                        // isClearable
                        classNamePrefix="select"
                        placeholder="Pilih Status"
                        value={optionStatus.find(
                          (option) => option.value === item.status
                        )}
                        onChange={(selectedOption) =>
                          this.handleChangeStatus(selectedOption, i)
                        }
                        options={optionStatus}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={2.5}>
                      <Select
                        // isClearable
                        classNamePrefix="select"
                        placeholder="Pilih Status"
                        value={optionStatus.find(
                          (option) => option.value === item.status
                        )}
                        options={optionStatus}
                        isDisabled={true}
                      />
                    </Grid>
                  )}
                </>
              ))}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#d0021b",
            }}
            startIcon={<Cancel />}
            onClick={() => this.handleCloseDialogFormEdit()}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Batal
            </Typography>
          </Button>
          &nbsp;&nbsp;
          <Button
            disabled={this.state.submitLoading}
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#004dcf",
            }}
            startIcon={<Save />}
            onClick={() => this.checkData()}
          >
            {this.state.submitLoading === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Simpan
              </Typography>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  doAddMultipleBukaan = () => {
    this.setState({
      setOpenAddMultiBukaan: true,
      newSetTime: this.state.setTime,
    });
    let newData = { nomor: "" };

    for (let i = 1; i < this.state.totalPrize; i++) {
      this.state.dataInfoPrize_n.push(newData);
    }
  };

  doCloseAddMultipleBukaan = () => {
    this.setState({
      setOpenAddMultiBukaan: false,
      dataInfoPrize_n: [{ nomor: "", timeActive: "" }],
      newSetTime: "",
    });
  };

  doNewGenerateNomor = () => {
    let newGenerateNumber = generateDataRandom(
      parseInt(this.state.totalPrize),
      parseInt(this.state.digitBukaan)
    );

    let geNomorGenerate = [];
    newGenerateNumber.map((obj, i) => {
      // console.log(obj);
      geNomorGenerate.push({ nomor: obj });
    });

    // console.log("newGenerateNumber: ", geNomorGenerate);

    this.setState({
      dataInfoBukaan_n: geNomorGenerate,
    });
  };

  //Prize List
  renderFormMultipleBukaan = () => {
    const isValid = this.validateDataPrizeInfo();
    const digitPrize = this.state.digitBukaan;
    const totalListPrize = this.state.totalPrize;

    return (
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={this.state.setOpenAddMultiBukaan}
        fullWidth="md"
        maxWidth="md"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography variant="h5" style={stylesDialog.title}>
              Tambah Prize
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.doCloseAddMultipleBukaan()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <Box sx={{ flexGrow: 1 }} style={{ marginBottom: 60, marginTop: 20 }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    color: "#000",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                >
                  Set Tanggal
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Input
                  autoComplete="off"
                  type="date"
                  name="newDatePrize"
                  id="newDatePrize"
                  value={this.state.newDatePrize}
                  onChange={(event) => {
                    // console.log("date: ", event.target.value);
                    this.setState({ newDatePrize: event.target.value });
                  }}
                />
              </Grid>

              <Grid item xs={4}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    color: "#000",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                >
                  Digit Prize
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Input
                  autoComplete="off"
                  type="number"
                  name="digitPrize"
                  id="digitPrize"
                  value={this.state.digitBukaan}
                  onChange={(event) =>
                    this.setState({ digitBukaan: event.target.value })
                  }
                />
              </Grid>

              <Grid item xs={4}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    color: "#000",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                >
                  Total Prize
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Input
                  autoComplete="off"
                  type="number"
                  name="totalListPrize"
                  id="totalListPrize"
                  value={this.state.totalPrize}
                  onChange={(event) =>
                    this.setState({ totalPrize: event.target.value })
                  }
                />
              </Grid>

              {digitPrize !== 0 && totalListPrize !== 0 ? (
                <>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={8}>
                    <Button
                      disabled={this.state.showLoadingGenerate}
                      variant="contained"
                      size="large"
                      style={{
                        backgroundColor: "#004dcf",
                      }}
                      startIcon={<EightMp />}
                      onClick={() => this.doNewRandomPrize()}
                    >
                      {this.state.showLoadingGenerate === true ? (
                        <CircularProgress style={{ color: "#fff" }} size={24} />
                      ) : (
                        <Typography
                          variant="button"
                          style={{
                            color: "#fff",
                            textTransform: "capitalize",
                          }}
                        >
                          Generate Nomor
                        </Typography>
                      )}
                    </Button>
                  </Grid>
                </>
              ) : null}

              {isValid
                ? this.state.dataInfoPrize_n.map((item, i) => (
                    <>
                      <Grid item xs={2}>
                        <Typography
                          component="span"
                          variant="subtitle1"
                          style={{
                            float: "left",
                            marginTop: 6,
                            color: "#000",
                            fontWeight: "bold",
                            textTransform: "capitalize",
                          }}
                        >
                          {i + 1}st Prize
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Input
                          autoComplete="off"
                          type="text"
                          name="nomor"
                          id="nomor"
                          maxLength={parseInt(this.state.digitPrize)}
                          value={item.nomor}
                          onChange={(event, newValue) => {
                            this.changeNewNomorPrize(event.target.value, i);
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          component="span"
                          variant="subtitle1"
                          style={{
                            float: "left",
                            marginTop: 6,
                            color: "#000",
                            fontWeight: "bold",
                            textTransform: "capitalize",
                          }}
                        >
                          Set Time
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Input
                          autoComplete="off"
                          type="time"
                          name="setTimePrize"
                          id="setTimePrize"
                          value={item.timeActive}
                          onChange={(event, newTime) => {
                            this.changeNewTimePrize(event.target.value, i);
                          }}
                        />
                      </Grid>
                    </>
                  ))
                : null}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#d0021b",
            }}
            startIcon={<Cancel />}
            onClick={() => this.doCloseAddMultipleBukaan()}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Batal
            </Typography>
          </Button>
          &nbsp;&nbsp;
          <Button
            disabled={this.state.submitLoading}
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#004dcf",
            }}
            startIcon={<Save />}
            onClick={() => this.checkDataPrize()}
          >
            {this.state.submitLoading === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Simpan
              </Typography>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  doNewRandomPrize = () => {
    // console.log("dataInfoPrize_n", this.state.dataInfoPrize_n);
    let newRandomPrize = generateDataRandom(
      parseInt(this.state.totalPrize),
      parseInt(this.state.digitBukaan)
    );

    let getNomorRandomPrize = [];
    newRandomPrize.map((obj, i) => {
      // console.log(obj);
      getNomorRandomPrize.push({ nomor: obj, timeActive: "" });
    });

    this.setState({
      dataInfoPrize_n: getNomorRandomPrize,
    });
  };

  validateDataPrizeInfo() {
    const { dataInfoPrize_n } = this.state;
    let isValid = true;
    for (let i = 0; i < dataInfoPrize_n.length; i++) {
      if (dataInfoPrize_n[i].nomor === "") {
        isValid = false;
        break;
      }
    }

    return isValid;
  }

  handleChange = (e) => {
    this.setState({
      accordionInfoWebsite: e,
    });
  };

  handlePageChange = (event, value) => {
    // console.log("value: ", value);
    let pageIndex = value - 1;
    // setPage(value);
    this.setState({ pageIdx: pageIndex, pages: value });
    this.doPagingIdx(pageIndex);
  };

  render() {
    return (
      <div>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper
                style={{
                  borderLeft: 6,
                  borderColor: "#cccccc",
                  backgroundColor: "#cccccc",
                  maxHeight: 100,
                  padding: 16,
                }}
              >
                <div className="page-header">
                  <Button
                    variant="contained"
                    size="small"
                    style={{
                      backgroundColor: "#000",
                    }}
                    startIcon={<ArrowBackIos />}
                    onClick={() => this.props.history.push("/panel/website")}
                  >
                    <Typography
                      variant="button"
                      style={{
                        fontSize: 12,
                        color: "#fff",
                        textTransform: "capitalize",
                        marginLeft: -10,
                      }}
                    >
                      Kembali
                    </Typography>
                  </Button>{" "}
                  <Typography
                    component="span"
                    variant="h2"
                    style={{
                      fontSize: 16,
                      color: "#000",
                      fontWeight: "bold",
                      textTransform: "capitalize",
                      float: "right",
                    }}
                  >
                    Ubah Website
                  </Typography>
                  <span className="dash">&nbsp;&nbsp;</span>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <br></br>
        <Accordion defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              component="span"
              variant="subtitle1"
              style={{
                textTransform: "capitalize",
                color: "#000",
                fontWeight: "bold",
              }}
            >
              INFO WEBSITE
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="box-container">
              <Box sx={{ flexGrow: 1 }}>
                <div className="">
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      <Typography
                        component="span"
                        variant="subtitle1"
                        style={{
                          float: "left",
                          marginTop: 6,
                          textTransform: "capitalize",
                        }}
                      >
                        Agent ID
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Input
                        autoComplete="off"
                        type="text"
                        name="agentId"
                        id="agentId"
                        value={this.state.agentId}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <Typography
                        component="span"
                        variant="subtitle1"
                        style={{
                          float: "left",
                          marginTop: 6,
                          textTransform: "capitalize",
                        }}
                      >
                        Nama Project
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Input
                        autoComplete="off"
                        type="text"
                        name="projectName"
                        id="projectName"
                        value={this.state.projectName}
                        onChange={(event) =>
                          this.setState({ projectName: event.target.value })
                        }
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <Typography
                        component="span"
                        variant="subtitle1"
                        style={{
                          float: "left",
                          marginTop: 6,
                          textTransform: "capitalize",
                        }}
                      >
                        Url Project
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Input
                        autoComplete="off"
                        type="text"
                        name="projectUrl"
                        id="projectUrl"
                        value={this.state.projectUrl}
                        onChange={(event) =>
                          this.setState({ projectUrl: event.target.value })
                        }
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <Typography
                        component="span"
                        variant="subtitle1"
                        style={{
                          float: "left",
                          marginTop: 6,
                          textTransform: "capitalize",
                        }}
                      >
                        Digit Bukaan
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Input
                        autoComplete="off"
                        type="number"
                        name="digitBukaan"
                        id="digitBukaan"
                        value={this.state.digitBukaan}
                        onChange={(event) =>
                          this.setState({ digitBukaan: event.target.value })
                        }
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <Typography
                        component="span"
                        variant="subtitle1"
                        style={{
                          float: "left",
                          marginTop: 6,
                          textTransform: "capitalize",
                        }}
                      >
                        Total Prize
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Input
                        autoComplete="off"
                        type="number"
                        name="totalPrize"
                        id="totalPrize"
                        value={this.state.totalPrize}
                        onChange={(event) =>
                          this.setState({ totalPrize: event.target.value })
                        }
                      />
                    </Grid>

                    {/* <Grid item xs={2}>
                      <Typography
                        component="span"
                        variant="subtitle1"
                        style={{
                          float: "left",
                          marginTop: 6,
                          textTransform: "capitalize",
                        }}
                      >
                        Set Waktu
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Input
                        autoComplete="off"
                        type="time"
                        name="setTime"
                        id="setTime"
                        value={this.state.setTime}
                        onChange={(event) =>
                          this.setState({ setTime: event.target.value })
                        }
                      />
                    </Grid> */}

                    <Grid item xs={2}>
                      <Typography
                        component="span"
                        variant="subtitle1"
                        style={{
                          float: "left",
                          marginTop: 6,
                          textTransform: "capitalize",
                        }}
                      >
                        Status
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Select
                        // isClearable
                        classNamePrefix="select"
                        placeholder="Pilih Status"
                        value={this.state.selectedStatusRunning}
                        onChange={this.handleChangeStatusRunning}
                        options={this.state.optionStatusRunning}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Box>
              <br></br>
              <div className="form-button-container">
                <br></br>
                <Button
                  variant="contained"
                  size="medium"
                  style={{
                    backgroundColor: "#d0021b",
                  }}
                  startIcon={<Cancel />}
                  onClick={() => this.props.history.push("/panel/website")}
                >
                  <Typography
                    variant="button"
                    style={{
                      color: "#fff",
                      textTransform: "capitalize",
                      marginLeft: -6,
                    }}
                  >
                    Batal
                  </Typography>
                </Button>{" "}
                &nbsp;&nbsp;
                <Button
                  variant="contained"
                  size="medium"
                  style={{
                    backgroundColor: "#004dcf",
                  }}
                  startIcon={<Save />}
                  onClick={() => this.doSubmit()}
                >
                  <Typography
                    variant="button"
                    style={{
                      color: "#fff",
                      textTransform: "capitalize",
                      marginLeft: -6,
                    }}
                  >
                    Simpan
                  </Typography>
                </Button>{" "}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        {this.renderSuccess()}
        <br></br>
        <Accordion defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography
              component="span"
              variant="subtitle1"
              style={{
                textTransform: "capitalize",
                color: "#000",
                fontWeight: "bold",
              }}
            >
              PRIZE LIST
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="box-container">
              <div className="form-button-container">
                {/* <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#0693e3",
                  }}
                  startIcon={<EightMp />}
                  onClick={() => this.doGenerateBukaan()}
                >
                  <Typography
                    variant="button"
                    style={{
                      color: "#fff",
                      textTransform: "capitalize",
                      marginLeft: -6,
                    }}
                  >
                    Generate Nomor
                  </Typography>
                </Button>{" "}
                &nbsp;&nbsp; */}
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#4caf50",
                  }}
                  startIcon={<AddBox />}
                  onClick={() => this.doAddMultipleBukaan()}
                >
                  <Typography
                    variant="button"
                    style={{
                      color: "#fff",
                      textTransform: "capitalize",
                      marginLeft: -6,
                    }}
                  >
                    Tambah Prize
                  </Typography>
                </Button>{" "}
              </div>
              <Stack spacing={2}>
                <Pagination
                  variant="outlined"
                  shape="rounded"
                  count={this.state.pagesCount}
                  page={this.state.pages}
                  siblingCount={1}
                  boundaryCount={1}
                  onChange={this.handlePageChange}
                />
              </Stack>
              <br></br>
              {this.renderTable()}
            </div>
          </AccordionDetails>
        </Accordion>

        {this.renderValidationAlert()}
        {this.renderGenerateAlert()}
        {this.renderDialogForm()}
        {this.renderFormMultipleBukaan()}
        {this.renderRemoveItemInfo()}
      </div>
    );
  }
}
export default EditWebsitePage;
