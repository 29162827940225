import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Menu.style.css";
import { activeLanguage } from "../../../config";
import { getLanguage } from "../../../languages";
import { Badge } from "reactstrap";
import {
  Dashboard,
  Settings,
  SupervisedUserCircle,
  Ballot,
  Public,
  FeaturedVideo,
  AutoAwesomeMosaic,
  Apps,
  Contacts,
  History,
  PrivacyTip,
  HorizontalSplit,
} from "@mui/icons-material";
import { Typography } from "@mui/material";
const stylesListComent = {
  inline: {
    display: "inline",
  },
};

class Menu extends Component {
  constructor(props) {
    super(props);

    this.language = getLanguage(activeLanguage, "Menu");

    this.state = {
      role: 0,
      menus: [],
      loginInfo: props.loginInfo,
      community: props.community,
      currentParent: 0,
      currentChild: -1,
      count: 0,
    };
    this.selectRole(props.loginInfo);
  }

  componentDidMount = () => {};

  componentWillReceiveProps = (props) => {
    if (props.community !== this.state.community) {
      this.selectRole(props.loginInfo, props.community);
    }
    this.setState({ loginInfo: props.loginInfo, community: props.community });
  };

  selectRole = (loginInfo, community) => {
    this.setState({ role: loginInfo.logintype });
    this.selectType(loginInfo.logintype, community);
  };

  selectType = (role, community) => {
    if (role === 1) {
      this.menuSuperAdmin(community);
    } else if (role === 2) {
      this.menuSubAdmin(community);
    }
  };

  toogleSubMenu = (menu, idx) => {
    let menus = this.state.menus;

    menus[idx].openChild = !menus[idx].openChild;
    this.setState({ menus: menus });
  };

  menuSuperAdmin = (community) => {
    let tmpMenu = [];
    tmpMenu.push(
      {
        id: 0,
        label: this.language["dashboard"],
        to: "/panel/dashboard",
        openChild: false,
        childs: [],
      },
      {
        id: 2,
        label: "Master Data",
        to: "",
        openChild: false,
        childs: [
          {
            id: 20,
            label: "Website",
            to: "/panel/website",
          },
          {
            id: 21,
            label: "Whitelist IP",
            to: "/panel/whitelist-ip",
          },
        ],
      },
      {
        id: 3,
        label: "CMS",
        to: "",
        openChild: false,
        childs: [
          {
            id: 30,
            label: "Banner",
            to: "/panel/banner",
          },
        ],
      },
      {
        id: 4,
        label: "History",
        to: "/panel/history",
        openChild: false,
        childs: [],
      },
      {
        id: 5,
        label: "Log Activity",
        to: "/panel/log-activity",
        openChild: false,
        childs: [],
      },
      {
        id: 6,
        label: "Settings",
        to: "",
        openChild: false,
        childs: [
          {
            id: 60,
            label: "User",
            to: "/panel/user",
          },
        ],
      }
    );

    this.setState({
      menus: tmpMenu,
    });
  };

  menuSubAdmin = (role) => {
    this.setState({
      menus: [
        {
          id: 0,
          label: this.language["dashboard"],
          to: "/panel/dashboard",
          openChild: false,
          childs: [],
        },
        {
          id: 2,
          label: "Master Data",
          to: "",
          openChild: false,
          childs: [
            {
              id: 20,
              label: "Website",
              to: "/panel/website",
            },
          ],
        },
        {
          id: 3,
          label: "CMS",
          to: "",
          openChild: false,
          childs: [
            {
              id: 30,
              label: "Banner",
              to: "/panel/banner",
            },
          ],
        },
        {
          id: 4,
          label: "History",
          to: "/panel/history",
          openChild: false,
          childs: [],
        },
      ],
    });
  };

  updateParent = (id) => {
    this.setState({ currentParent: id, currentChild: -1 });
  };

  updateChild = (id) => {
    this.setState({ currentChild: id, currentParent: -1 });
  };

  iconBadge = () => {
    if (this.state.count !== 0) {
      return (
        <Badge color="primary" pill>
          {this.state.count}
        </Badge>
      );
    }
  };

  renderforBadge = (submenu) => {
    // console.log(submenu);
    if (submenu.label === "Order List") {
      return (
        <div
          className={`submenu ${
            this.state.currentChild === submenu.id ? "menu-active" : ""
          } `}
        >
          {this.renderSubMenuIcon(submenu.label)}{" "}
          <Typography
            component="span"
            variant="subtitle2"
            style={
              (stylesListComent.inline,
              {
                color: "#fff",
                fontWeight: "bolder",
              })
            }
          >
            {submenu.label}
          </Typography>
          &nbsp;
          {this.iconBadge()}
        </div>
      );
    } else {
      return (
        <div
          className={`submenu ${
            this.state.currentChild === submenu.id ? "menu-active" : ""
          } `}
        >
          {this.renderSubMenuIcon(submenu.label)}{" "}
          <Typography
            component="span"
            variant="subtitle2"
            style={
              (stylesListComent.inline,
              {
                color: "#fff",
                fontWeight: "bolder",
              })
            }
          >
            {submenu.label}
          </Typography>
        </div>
      );
    }
  };

  _renderSubMenu = (menu, idx) => {
    // console.log(menu);
    // this.loadCount();
    if (menu.openChild) {
      if (
        menu.label === "Marketplace" &&
        menu.childs[2].label === "Order List"
      ) {
        return (
          <div className="submenu-container">
            {menu.childs.map((submenu, i) => (
              <Link
                key={submenu.id}
                to={submenu.to}
                onClick={() => this.updateChild(submenu.id)}
                className={`link-custom`}
              >
                {this.renderforBadge(submenu)}
              </Link>
            ))}
          </div>
        );
      }
      return (
        <div className="submenu-container">
          {menu.childs.map((submenu, i) => (
            <Link
              key={submenu.id}
              to={submenu.to}
              onClick={() => this.updateChild(submenu.id)}
              className={`link-custom`}
            >
              <div
                className={`submenu ${
                  this.state.currentChild === submenu.id ? "menu-active" : ""
                } `}
              >
                {this.renderSubMenuIcon(submenu.label)}{" "}
                <Typography
                  component="span"
                  variant="subtitle2"
                  style={
                    (stylesListComent.inline,
                    {
                      color: "#fff",
                      fontWeight: "bolder",
                    })
                  }
                >
                  {submenu.label}
                </Typography>
              </div>
            </Link>
          ))}
        </div>
      );
    } else {
      return "";
    }
  };

  _renderChevronIcon = (menu) => {
    if (menu.openChild) {
      return <FontAwesomeIcon icon="chevron-down" />;
    } else {
      return <FontAwesomeIcon icon="chevron-left" />;
    }
  };

  _renderMenu = (menu, i) => {
    // console.log(menu);

    if (menu.to === "") {
      if (menu.label === "Marketplace") {
        return (
          <div key={menu.id}>
            <div
              className="menu menu-parent"
              onClick={() => this.toogleSubMenu(menu, i)}
            >
              {this.renderIconMenu(menu.label)}{" "}
              <Typography
                component="span"
                variant="subtitle2"
                style={
                  (stylesListComent.inline,
                  {
                    color: "#fff",
                    fontWeight: "bolder",
                  })
                }
              >
                {menu.label}
              </Typography>
              <div className="chevron-container">
                {this._renderChevronIcon(menu)}
              </div>
            </div>
            {this._renderSubMenu(menu, i)}
          </div>
        );
      }
      return (
        <div key={menu.id}>
          <div
            className="menu menu-parent"
            onClick={() => this.toogleSubMenu(menu, i)}
          >
            {this.renderIconMenu(menu.label)}{" "}
            <Typography
              component="span"
              variant="subtitle2"
              style={
                (stylesListComent.inline,
                {
                  color: "#fff",
                  fontWeight: "bolder",
                })
              }
            >
              {menu.label}
            </Typography>
            <div className="chevron-container">
              {this._renderChevronIcon(menu)}
            </div>
          </div>
          {this._renderSubMenu(menu, i)}
        </div>
      );
    } else {
      return (
        <Link
          key={menu.id}
          to={menu.to}
          onClick={() => this.updateParent(menu.id)}
          className={`link-custom`}
        >
          <div
            className={`menu ${
              this.state.currentParent === menu.id ? "menu-active" : ""
            } `}
          >
            {this.renderIconMenu(menu.label)}{" "}
            <Typography
              component="span"
              variant="subtitle2"
              style={
                (stylesListComent.inline,
                {
                  color: "#fff",
                  fontWeight: "bolder",
                })
              }
            >
              {menu.label}
            </Typography>{" "}
            <div className="chevron-container"></div>
          </div>
        </Link>
      );
    }
  };

  renderIconMenu = (label) => {
    if (label === "Dashboard") {
      return <Dashboard />;
    } else if (label === "Master Data") {
      return <Ballot />;
    } else if (label === "Settings") {
      return <Settings />;
    } else if (label === "CMS") {
      return <FeaturedVideo />;
    } else if (label === "History") {
      return <History />;
    } else if (label === "Log Activity") {
      return <HorizontalSplit />;
    }
  };

  renderSubMenuIcon = (label) => {
    if (label === "Website") {
      return <Public />;
    } else if (label === "User") {
      return <SupervisedUserCircle />;
    } else if (label === "Banner") {
      return <AutoAwesomeMosaic />;
    } else if (label === "Logo") {
      return <Apps />;
    } else if (label === "Contact") {
      return <Contacts />;
    } else if (label === "Whitelist IP") {
      return <PrivacyTip />;
    }
  };

  render() {
    return (
      <div className="menu-container">
        {this.state.menus.map((menu, i) => this._renderMenu(menu, i))}
      </div>
    );
  }
}
export default Menu;
