import React, { Component } from "react";
import { apiOptionWebsite } from "../../Service/api";
import { Box, Paper, Grid, Typography, Button, Checkbox } from "@mui/material";
import { Contacts, Delete, Feed, AddBox, Refresh } from "@mui/icons-material";
import ReactTable from "react-table";
import Select from "react-select";

const stylesListComent = {
  inline: {
    display: "inline",
  },
};
class KontakPage extends Component {
  constructor(props) {
    super(props);
    this.reactTable = React.createRef();
    this.fileExcel = React.createRef();
    this.state = {
      tableData: [],
      tableDisplay: [],
      filter: "",
      groupShow: [],
      selectedGroupId: null,
      openAlertDelete: false,
      itemDeleted: {},
      setOpenAdd: false,
      setOpenEdit: false,
      item_detail: {},
      selectAll: false,
      checked: [],
      selectRowData: [],
      openSelectedRow: false,
      openAlertDeleteAll: false,
      setOpenDialogImport: false,
      dataJson: [],
      uploadLoading: false,
      file: null,
      openValidation: false,
      titleValidation: "",
      titleBody: "",
      showLoadingSubmit: false,
      selectWebsiteId: null,
      optionDataWebsite: [],
    };
  }

  componentWillMount() {}

  componentDidMount() {
    this.doLoadOption();
  }

  addNew = () => {
    this.props.history.push("/panel/input-kontak");
  };

  doLoadOption = () => {
    apiOptionWebsite()
      .then((res) => {
        console.log(res);
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            this.setState({
              optionDataWebsite: data.records,
              selectWebsiteId: data.records[0],
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  pressReset = () => {
    console.log("reset");
    this.setState({
      filter: "",
      selectedGroupId: null,
      openSuccess: false,
      openSuccessText: "",
      selectAll: false,
      selectRowData: [],
      checked: [],
    });
  };

  handleChangeWebsite = (selectWebsiteId) => {
    this.setState({
      selectWebsiteId: selectWebsiteId,
    });
  };

  render() {
    return (
      <Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper
                style={{
                  borderLeft: 6,
                  borderColor: "#07213c",
                  backgroundColor: "#07213c",
                  maxHeight: 100,
                  padding: 16,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    color: "white",
                  }}
                >
                  <Contacts style={{ marginRight: 10 }} />
                  <Typography
                    component="span"
                    variant="h1"
                    style={
                      (stylesListComent.inline,
                      {
                        fontSize: 20,
                        color: "#FFF",
                        fontWeight: "bold",
                      })
                    }
                  >
                    Contact
                  </Typography>
                </div>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper
                style={{
                  borderLeft: 6,
                  borderColor: "#2f55a2",
                  backgroundColor: "#cccccc",
                  maxHeight: 100,
                  padding: 16,
                  paddingBottom: 24,
                }}
              >
                <div className="contentDate">
                  <div style={{ marginRight: 16, width: 200 }}>
                    <Select
                      classNamePrefix="select"
                      placeholder="Pilih Website"
                      value={this.state.selectWebsiteId}
                      onChange={this.handleChangeWebsite}
                      options={this.state.optionDataWebsite}
                    />
                  </div>
                  <div style={{ marginRight: 16 }}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#4caf50",
                      }}
                      startIcon={<AddBox />}
                      onClick={() => this.addNew()}
                    >
                      <Typography
                        variant="button"
                        style={{
                          fontSize: 14,
                          color: "#fff",
                          textTransform: "capitalize",
                        }}
                      >
                        Tambah
                      </Typography>
                    </Button>
                  </div>
                  <div style={{ marginRight: 0 }}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#1273DE",
                      }}
                      startIcon={<Refresh />}
                      onClick={() => this.pressReset()}
                    >
                      <Typography
                        variant="button"
                        style={{
                          fontSize: 14,
                          color: "#fff",
                          textTransform: "capitalize",
                        }}
                      >
                        Refresh
                      </Typography>
                    </Button>
                  </div>
                </div>
                <br></br>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <br></br>
        <div className="box-container">
          <ReactTable
            ref={(r) => (this.reactTable = r)}
            data={this.state.tableData}
            columns={[
              {
                Header: (
                  <Checkbox
                    style={{
                      width: 30,
                      height: 30,
                      color: "#3a6cf6",
                    }}
                    onChange={(e) => this.toggleSelectAll(e)}
                    checked={this.state.selectAll}
                    inputProps={{
                      "aria-labelledby": "checkbox-list-secondary-label",
                    }}
                  />
                ),
                Cell: (row) => (
                  <Checkbox
                    style={{
                      width: 30,
                      height: 30,
                      color: "#3a6cf6",
                    }}
                    defaultChecked={this.state.checked[row.index]}
                    checked={this.state.checked[row.index]}
                    onChange={(e) => this.handleCheck(e, row.index)}
                    inputProps={{
                      "aria-labelledby": "checkbox-list-secondary-label",
                    }}
                  />
                ),
                headerStyle: { fontWeight: "bold" },
                sortable: false,
                filterable: true,
                style: { textAlign: "center" },
                width: 100,
              },
              {
                Header: "Project",
                headerStyle: { fontWeight: "bold" },
                accessor: "project_name",
                style: { textAlign: "center" },
              },
              {
                Header: "Tipe Logo",
                headerStyle: { fontWeight: "bold" },
                accessor: "url",
                style: { textAlign: "center" },
              },
              {
                Header: "Nama Logo",
                headerStyle: { fontWeight: "bold" },
                accessor: "url",
                style: { textAlign: "center" },
              },
              {
                Header: "Logo",
                headerStyle: { fontWeight: "bold" },
                accessor: "url",
                style: { textAlign: "center" },
              },

              {
                Header: "Action",
                headerStyle: { fontWeight: "bold" },
                accessor: "",
                style: { textAlign: "center" },
                width: 200,
                Cell: (e) => (
                  <div>
                    <Button
                      variant="contained"
                      size="small"
                      style={{
                        backgroundColor: "#3f51b5",
                      }}
                      startIcon={<Feed />}
                      onClick={() => this.doRowEdit(e.original)}
                    >
                      <Typography
                        variant="button"
                        style={{
                          fontSize: 14,
                          color: "#fff",
                          textTransform: "capitalize",
                        }}
                      >
                        Ubah
                      </Typography>
                    </Button>
                    &nbsp;
                    <Button
                      variant="contained"
                      size="small"
                      style={{
                        backgroundColor: "#ff0000",
                      }}
                      startIcon={<Delete />}
                      onClick={() => this.doRowDeleteInfo(e.original)}
                    >
                      <Typography
                        variant="button"
                        style={{
                          fontSize: 14,
                          color: "#fff",
                          textTransform: "capitalize",
                        }}
                      >
                        Hapus
                      </Typography>
                    </Button>
                  </div>
                ),
              },
            ]}
            style={{ backgroundColor: "#f2f2f2" }}
            filterable
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id])
                .toLowerCase()
                .includes(filter.value.toLowerCase())
            }
            defaultPageSize={5}
          />
        </div>
      </Box>
    );
  }
}
export default KontakPage;
