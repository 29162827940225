import React, { Component } from "react";
import { apiOptionWebsite, apiLogoInsertUpdate } from "../../Service/api";
import {
  Typography,
  Button,
  Grid,
  Box,
  Paper,
  Dialog,
  DialogTitle,
  Card,
  CardContent,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Slide,
  Stack,
  Alert,
} from "@mui/material";
import { PhotoCamera, Save, Close } from "@mui/icons-material";
import { DropzoneDialog } from "material-ui-dropzone";
import { Container, Row, Col, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ArrowBackIos, Cancel } from "@mui/icons-material";
import Select from "react-select";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const stylesDialog = {
  appBar: {
    position: "relative",
    backgroundColor: "#0d47a1",
  },
  title: {
    marginLeft: 0,
    flex: 1,
    fontSize: 16,
  },
};

class InputKontakPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id_logo: 0,
      agentId: "",
      projectUrl: "",
      setTime: "",
      nomorPrize: "",
      prizeList: [],
      showLoadingSubmit: false,
      openSuccess: false,
      openSuccessText: "",
      tableData: [],
      tableDisplay: [],
      filter: "",
      groupShow: [],
      selectedGroupId: null,
      openAlertDelete: false,
      itemDeleted: {},
      setOpenAdd: false,
      setOpenEdit: false,
      item_detail: {},
      selectAll: false,
      checked: [],
      selectRowData: [],
      openSelectedRow: false,
      openAlertDeleteAll: false,
      setOpenDialogImport: false,
      dataJson: [],
      uploadLoading: false,
      file: null,
      openValidation: false,
      titleValidation: "",
      titleBody: "",
      selectWebsiteId: null,
      optionDataWebsite: [],
      bannerpic: [],
      bannerpicLimit: 1,
      logoName: "",
    };
  }

  componentDidMount() {
    this.doLoadOption();
  }

  doLoadOption = () => {
    apiOptionWebsite()
      .then((res) => {
        console.log(res);
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            this.setState({
              optionDataWebsite: data.records,
              selectWebsiteId: data.records[0],
              projectName: data.records[0].label,
              projectUrl: data.records[0].url,
              agentId: data.records[0].value,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  pressReset = () => {
    console.log("reset");
    this.setState({
      filter: "",
      selectedGroupId: null,
      openSuccess: false,
      openSuccessText: "",
      selectAll: false,
      selectRowData: [],
      checked: [],
    });
  };

  handleChangeWebsite = (selectWebsiteId) => {
    this.setState({
      selectWebsiteId: selectWebsiteId,
      projectName: selectWebsiteId.label,
      projectUrl: selectWebsiteId.url,
      agentId: selectWebsiteId.value,
    });
  };

  onUploadImage = (result) => {
    this.setState({ bannerpic: result });
  };

  handleClose() {
    console.log("cek");
    this.setState({
      open: false,
    });
  }

  handleSave(files) {
    console.log("cek", files);
    for (let i = 0; i < files.length; i++) {
      let file = files[i];

      this.getImage(file);
    }
    this.setState({
      files: files,
      open: false,
    });
  }

  getImage = (file) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      let lists = this.state.bannerpic;
      lists.push(e.target.result);
      console.log("cek bannerpic", lists);
      this.setState({ bannerpic: lists });
    };
    reader.readAsDataURL(file);
  };

  handleOpen() {
    this.setState({
      open: true,
    });
  }
  doSubmit = () => {
    const { logoName, bannerPic, selectWebsiteId, agentId } = this.state;
    if (logoName === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Nama project kosong?",
        titleBody: "Silahkan isi nama project",
      });
    } else if (bannerPic === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Logo Project kosong?",
        titleBody: "Silahkan isi Logo project",
      });
    } else if (selectWebsiteId === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Logo Project kosong?",
        titleBody: "Silahkan isi Logo project",
      });
    } else if (agentId === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Logo Project kosong?",
        titleBody: "Silahkan isi Logo project",
      });
    } else {
      let params = {
        id_logo: this.state.id_logo,
        agentId: this.state.selectWebsiteId.value,
        bannerpic: this.state.bannerpic,
        logoName: this.state.logoName,
      };
      console.log(params);
      this.props.doLoading();
      this.setState({ showLoadingSubmit: true });
      apiLogoInsertUpdate(params)
        .then((res) => {
          console.log(res);
          this.props.doLoading();
          this.setState({
            showLoadingSubmit: false,
            openSuccess: true,
            openSuccessText: "Data berhasil disimpan",
          });
        })
        .catch((err) => {
          console.log(err);
          this.props.doLoading();
          this.setState({ showLoadingSubmit: false });
        });
    }
  };

  handleCloseValidationAlert = () => {
    this.setState({
      openValidation: false,
      titleValidation: "",
      titleBody: "",
    });
  };

  renderValidationAlert = () => {
    return (
      <Dialog
        open={this.state.openValidation}
        onClose={this.handleCloseValidationAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        fullWidth="xs"
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="label-title-alert">{this.state.titleValidation}</div>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <div className="title-body-alert"> {this.state.titleBody} </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton
            style={{ backgroundColor: "#f2f2f2" }}
            onClick={this.handleCloseValidationAlert}
          >
            <Close />
          </IconButton>
        </DialogActions>
      </Dialog>
    );
  };

  closeStackSuccess = () => {
    this.setState({
      openSuccess: false,
      openSuccessText: "",
    });
    this.props.history.push("/panel/website");
  };

  renderSuccess = () => {
    if (this.state.openSuccess === true) {
      setTimeout(() => this.closeStackSuccess(), 2000);

      return (
        <div style={{ margin: 10 }}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert
              variant="filled"
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  // onClick={() => this.reset()}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {this.state.openSuccessText}
            </Alert>
          </Stack>
        </div>
      );
    }
  };

  renderAmbilBannerPic = () => {
    if (
      this.state.bannerpic.length === 0 ||
      this.state.bannerpic.length < this.state.bannerpicLimit
    ) {
      return (
        <>
          <input style={{ display: "none" }} id="contained-button-file" />
          <label htmlFor="contained-button-file">
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleOpen.bind(this)}
              component="span"
              style={{
                backgroundColor: "#2e6da4",
              }}
              startIcon={<PhotoCamera />}
            >
              <Typography
                variant="subtitle1"
                style={
                  (stylesDialog.title,
                  {
                    color: "#fff",
                    fontSize: 15,
                    textTransform: "capitalize",
                  })
                }
              >
                Upload Logo
              </Typography>
            </Button>
          </label>{" "}
          <br></br>
          <span style={{ color: "red", fontSize: 12 }}>
            * Logo maximal size 1 MB dan limit 20 item{" "}
          </span>
          <DropzoneDialog
            acceptedFiles={["image/*"]}
            dropzoneText="Seret dan lepas gambar di sini atau klik"
            open={this.state.open}
            onSave={this.handleSave.bind(this)}
            onClose={this.handleClose.bind(this)}
            maxFileSize={1048576}
            filesLimit={this.state.bannerpicLimit}
            showPreviews={false}
            showPreviewsInDropzone={true}
          />
        </>
      );
    }
  };

  renderPicture() {
    if (this.state.bannerpic.length > 0) {
      return (
        <Container>
          <Row>
            {this.state.bannerpic.map((img, i) => (
              <Col xs="6" sm="4" md="3" className="pic-col" key={i}>
                <img src={img} alt="uploadpic" />
                <div className="delete">
                  <FontAwesomeIcon
                    icon="trash"
                    onClick={() => this.removePicture(i)}
                  />
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      );
    }
  }
  removePicture = (idx) => {
    let lists = this.state.bannerpic;
    lists.splice(idx, 1);
    this.setState({ bannerpic: lists });
  };

  render() {
    return (
      <div>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper
                style={{
                  borderLeft: 6,
                  borderColor: "#cccccc",
                  backgroundColor: "#cccccc",
                  maxHeight: 100,
                  padding: 16,
                }}
              >
                <div className="page-header">
                  <Button
                    variant="contained"
                    size="small"
                    style={{
                      backgroundColor: "#000",
                    }}
                    startIcon={<ArrowBackIos />}
                    onClick={() => this.props.history.push("/panel/kontak")}
                  >
                    <Typography
                      variant="button"
                      style={{
                        fontSize: 12,
                        color: "#fff",
                        textTransform: "capitalize",
                        marginLeft: -10,
                      }}
                    >
                      Kembali
                    </Typography>
                  </Button>{" "}
                  <Typography
                    component="span"
                    variant="h2"
                    style={{
                      fontSize: 16,
                      color: "#000",
                      fontWeight: "bold",
                      textTransform: "capitalize",
                      float: "right",
                    }}
                  >
                    Tambah Logo Website
                  </Typography>
                  <span className="dash">&nbsp;&nbsp;</span>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <br></br>
        <div className="box-container">
          <Box sx={{ flexGrow: 1 }}>
            <div className="">
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Nama Project
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <div style={{ marginRight: 16, width: 400 }}>
                    <Select
                      classNamePrefix="select"
                      placeholder="Pilih Website"
                      value={this.state.selectWebsiteId}
                      onChange={this.handleChangeWebsite}
                      options={this.state.optionDataWebsite}
                    />
                  </div>
                </Grid>

                <Grid item xs={2}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Nama Logo
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="projectName"
                    id="projectName"
                    value={this.state.logoName}
                    onChange={(event) =>
                      this.setState({ logoName: event.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Logo
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Card variant="outlined">
                    <CardContent>
                      {this.renderAmbilBannerPic()}
                      {this.renderPicture()}
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </Box>
          <br></br>
          <div className="form-button-container">
            <br></br>
            <Button
              variant="contained"
              size="medium"
              style={{
                backgroundColor: "#d0021b",
              }}
              startIcon={<Cancel />}
              onClick={() => this.props.history.push("/panel/website")}
            >
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                  marginLeft: -6,
                }}
              >
                Batal
              </Typography>
            </Button>{" "}
            &nbsp;&nbsp;
            <Button
              variant="contained"
              size="medium"
              style={{
                backgroundColor: "#004dcf",
              }}
              startIcon={<Save />}
              onClick={() => this.doSubmit()}
            >
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                  marginLeft: -6,
                }}
              >
                Simpan
              </Typography>
            </Button>{" "}
          </div>
        </div>
        <br></br>
        {this.renderValidationAlert()}
        {this.renderSuccess()}
      </div>
    );
  }
}
export default InputKontakPage;
