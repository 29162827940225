import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";
import createBrowserHistory from "history/createBrowserHistory";
import axios from "axios";
import { serverUrl } from "../config";
import "./Default.style.css";
import Header from "./Components/Header/Header";
import Menu from "./Components/Menu/Menu";
import MenuProfile from "./Components/MenuProfile/MenuProfile";
import Loading from "./Components/Loading/Loading";
import Dashboard from "./Pages/Dashboard/Dashboard";
import ListAdmin from "./Pages/ListAdmin/ListAdmin";
import EditAdmin from "./Pages/EditAdmin/EditAdmin";
import InputAdmin from "./Pages/InputAdmin/InputAdmin";
import ChangePassword from "./Pages/ChangePassword/ChangePassword";
import Profile from "./Pages/Profile/Profile";
import WebsitePage from "./Pages/Website";
import InputWebsitePage from "./Pages/Website/Input";
import EditWebsitePage from "./Pages/Website/Edit";
import BannerPage from "./Pages/Banner";
import LogoPage from "./Pages/Logo";
import KontakPage from "./Pages/Kontak";
import InputLogoPage from "./Pages/Logo/input";
import InputKontakPage from "./Pages/Kontak/input";
import HistoryPage from "./Pages/History";
import WhiteListIpAddressPage from "./Pages/WhiteListIP";
import InputWhiteListPage from "./Pages/WhiteListIP/input";
import EditWhiteListPage from "./Pages/WhiteListIP/edit";
import LogActivityPage from "./Pages/LogActivity";
// import AllContactPage from "./Pages/Contact";
// import TemplateContentPage from "./Pages/TemplateContent/Index";

class Default extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginInfo: {},
      menuProfileShow: false,
      community: {
        communityid: 0,
        communityname: "- SELECT COMMUNITY -",
        isdefault: 1,
      },
      communityList: [],
      loadingShow: false,
      redirect: false,
    };
    this.flag = {
      menuProfileJustOpen: false,
    };
    this.history = createBrowserHistory();

    this.history.listen((location, action) => {
      //event when change page
    });

    this.menuRef = null;
  }

  componentDidMount = () => {
    let loginInfo;

    if (
      this.props.location.state === undefined ||
      this.props.location.state === null
    ) {
      loginInfo = localStorage.getItem("loginInfo");

      if (
        loginInfo === undefined ||
        loginInfo === null ||
        loginInfo === "" ||
        loginInfo === "undefined" ||
        loginInfo === "null"
      ) {
        this.props.history.replace("/");
      } else {
        loginInfo = JSON.parse(loginInfo);
        this.setState({ loginInfo: loginInfo });
      }
    } else {
      loginInfo = this.props.location.state.loginInfo;
      this.setState({ loginInfo: this.props.location.state.loginInfo });
    }
    //set event click in document
    document.addEventListener("click", this.eventDocumentClick);
    if (loginInfo !== undefined) this.getCommunityAvailable(loginInfo.phoneno);
  };

  getCommunityAvailable = (phoneno) => {
    axios({
      method: "post",
      url: serverUrl + "cp_community_list_available_admin.php",
      data: { phoneno: phoneno },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => {
        let data = response.data;
        let defaultCommunity = {
          communityid: 0,
          communityname: "- SELECT COMMUNITY -",
          isdefault: 1,
        };
        let getDefault = false;

        data.records.forEach((comm) => {
          if (comm.isdefault === 1) {
            defaultCommunity = comm;
            getDefault = true;
          }
        });

        if (!getDefault && data.records.length > 0) {
          defaultCommunity = data.records[0];
        }
        this.setState({
          communityList: data.records,
          community: defaultCommunity,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentWillUnmount() {
    document.removeEventListener("click", this.eventDocumentClick);
  }

  eventDocumentClick = (event) => {
    //check if menu profile is open then close
    if (this.state.menuProfileShow) {
      if (this.flag.menuProfileJustOpen) {
        this.flag.menuProfileJustOpen = false;
      } else {
        this.setState({ menuProfileShow: !this.state.menuProfileShow });
      }
    }
  };

  onLogout = () => {
    localStorage.removeItem("loginInfo");
    this.props.history.replace("/");
  };
  toogleMenuProfile = () => {
    this.flag.menuProfileJustOpen = true;
    this.setState({ menuProfileShow: !this.state.menuProfileShow });
  };

  changeCommunity = (community) => {
    //console.log('change community : '+community);
    this.menuRef.updateParent(0);
    this.setState({ community: community });

    let { match } = this.props;
    //this.history.replace('/smartcp/panel/dashboard');
    this.history.replace(`${match.path}/dashboard`);
  };

  updateCommunity = () => {
    this.getCommunityAvailable();
  };

  doLoading = () => {
    this.setState({ loadingShow: !this.state.loadingShow });
  };

  render() {
    // let { match } = this.props;
    // console.log(`${match.path}/dashboard`);
    return (
      <Router history={this.history}>
        <div>
          <Loading isShow={this.state.loadingShow} />
          <Header
            loginInfo={this.state.loginInfo}
            community={this.state.community}
            communityList={this.state.communityList}
            toogleMenuProfile={this.toogleMenuProfile}
            changeCommunity={this.changeCommunity}
          ></Header>
          <div className="default-screen-desktop">
            <Menu
              ref={(ref) => (this.menuRef = ref)}
              loginInfo={this.state.loginInfo}
              community={this.state.community}
            ></Menu>
          </div>
          <MenuProfile
            isOpen={this.state.menuProfileShow}
            onLogout={this.onLogout}
          ></MenuProfile>
          <div className="content-container">
            <Switch>
              <Route
                path={`/panel/dashboard`}
                render={(props) => (
                  <Dashboard
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />

              <Route
                path={`/panel/changepassword`}
                render={(props) => (
                  <ChangePassword
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />

              <Route
                path={`/panel/profile`}
                render={(props) => (
                  <Profile
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />

              <Route
                path={`/panel/user`}
                render={(props) => (
                  <ListAdmin
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/add-user`}
                render={(props) => (
                  <InputAdmin
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/edit-user/:id_user`}
                render={(props) => (
                  <EditAdmin
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/website`}
                render={(props) => (
                  <WebsitePage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/tambah-website`}
                render={(props) => (
                  <InputWebsitePage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />

              <Route
                path={`/panel/input-logo`}
                render={(props) => (
                  <InputLogoPage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/input-kontak`}
                render={(props) => (
                  <InputKontakPage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />

              <Route
                path={`/panel/ubah-website/:id_agent`}
                render={(props) => (
                  <EditWebsitePage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/banner`}
                render={(props) => (
                  <BannerPage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/logo`}
                render={(props) => (
                  <LogoPage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/kontak`}
                render={(props) => (
                  <KontakPage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/history`}
                render={(props) => (
                  <HistoryPage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/whitelist-ip`}
                render={(props) => (
                  <WhiteListIpAddressPage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/tambah-whitelist`}
                render={(props) => (
                  <InputWhiteListPage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/ubah-whitelist/:idWhiteList`}
                render={(props) => (
                  <EditWhiteListPage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/log-activity`}
                render={(props) => (
                  <LogActivityPage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
            </Switch>
          </div>
        </div>
      </Router>
    );
  }
}

export default Default;
