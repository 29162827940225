export function convertToRupiah(number) {
  if (number) {
    var rupiah = "";

    var numberrev = number

      .toString()

      .split("")

      .reverse()

      .join("");

    for (var i = 0; i < numberrev.length; i++)
      if (i % 3 === 0) rupiah += numberrev.substr(i, 3) + ".";

    return (
      "Rp. " +
      rupiah

        .split("", rupiah.length - 1)

        .reverse()

        .join("")
    );
  } else {
    return number;
  }
}

export var Data_Config = {
  api_id: 12789006,
  api_hash: "3630f6df6b56de2bee4eab4001bc11e8",
  phone: +6282136180185,
  string_session:
    "1BQANOTEuMTA4LjU2LjIwMAG7TeDZRGpOS/hCKP+fBMnlhEGhtevEaUZF63ix9hn34a8/N487v83ZXA89WXoVlFzTcrjEGmqe/l7GsD007aCb8sglIHkkyS5sVl8cwzM5m63geKCSb9KpVxwhg8vZz43g3zmXgypm+GEZqCFz6PHKUElruAYjlhPgbxVbI/x5x/avA8XHasFxuNLVxUM43SSyOvqhKr9w7hgo0/1giOZzTof0/ahtA/0YFNC3wgPhnDvOi7v3TkBjOb7rrWld5p1Kgpjy958YMiX7GvuiE+tbgBV8YiWD0KAntuJYmm+DUKG9pKhvSoqt70Va257OajfNnz6cdvWqsoyXvNJLpngTgg==",
};

export var shuffle = (array) => {
  let currentIndex = array.length,
    randomIndex;
  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }
  return array;
};

export var getRoundRobin = (index, array) => {
  const arrData = array;
  const after = arrData.slice(index + 1, arrData.length);
  const before = arrData.slice(0, index + 1);

  return after.concat(before);
};

export var SetSeccondsFlood = (dateFlood, dateNow) => {
  var dif = (dateNow.getTime() - dateFlood.getTime()) / 1000;
  return dif;
};

export var formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

// export var formatNumber = (ctrl) => {
//   var val = ctrl;

//   val = val.replace(/,/g, "");
//   ctrl = "";
//   val += "";
//   let x = val.split(".");
//   let x1 = x[0];
//   let x2 = x.length > 1 ? "." + x[1] : "";

//   var rgx = /(\d+)(\d{3})/;

//   while (rgx.test(x1)) {
//     x1 = x1.replace(rgx, "$1" + "," + "$2");
//   }

//   return (ctrl = x1 + x2);
// };

export var isNegative = (num) => {
  if (Math.sign(num) === -1) {
    return true;
  }

  return false;
};

export function uuidRandomId() {
  var u = "",
    i = 0;
  while (i++ < 36) {
    var c = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx"[i - 1],
      r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    u += c === "-" || c === "4" ? c : v.toString(16);
  }
  return u;
}

export function generateData(size, digit) {
  var digits = digit;
  return Array.apply(null, { length: size || 10 }).map(function () {
    return Math.floor(
      Math.random() * Math.pow(10, digits) + Math.pow(10, digits)
    )
      .toString()
      .slice(-digits);
  });
}

export function generateDataRandom(size, digit) {
  var digits = digit;
  var digitArray = Array.from({ length: 10 }, (_, i) => i);

  return Array.apply(null, { length: size || 10 }).map(function () {
    for (var i = digitArray.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      [digitArray[i], digitArray[j]] = [digitArray[j], digitArray[i]];
    }

    var randomValue = digitArray.slice(0, digits);

    if (randomValue[0] === 0) {
      randomValue[0] = Math.floor(Math.random() * 9) + 1; // Replace 0 with a random non-zero digit
    }

    var randomNumber = randomValue.join("");
    return parseInt(randomNumber, 10);
  });
}

