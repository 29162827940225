import React, { Component } from "react";
import { Input } from "reactstrap";
import {
  apiAdminGetDetail,
  apiLoginType,
  apiAdminUpdate,
  apiOptionWebsite,
  apiLogActivityInsert,
  apiAdminUbahPassword,
} from "../../Service/api";
import PictureUploader from "../../Components/PictureUploader/PictureUploader";
import SelectMultiColumn from "../../Components/SelectMultiColumn/SelectMultiColumn";
import { activeLanguage } from "../../../config";
import { getLanguage } from "../../../languages";
import {
  ArrowBackIos,
  Cancel,
  Save,
  Close,
  LockOpen,
  Lock,
} from "@mui/icons-material";
import ButtonUI from "@mui/material/Button";
import {
  Typography,
  Box,
  Grid,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  Stack,
  Alert,
  IconButton,
  Slide,
  AppBar,
  Toolbar,
  Button,
  CircularProgress,
} from "@mui/material";
import Select from "react-select";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const stylesDialog = {
  appBar: {
    position: "relative",
    backgroundColor: "#03101e",
  },
  title: {
    marginLeft: 0,
    flex: 1,
    fontSize: 16,
  },
};

class EditAdmin extends Component {
  constructor(props) {
    super(props);
    this.globallang = getLanguage(activeLanguage, "global");
    this.language = getLanguage(activeLanguage, "editadmin");
    this.state = {
      id_user: props.match.params.id_user,
      phoneno: "",
      username: "",
      name: "",
      logintype: "",
      profilepic: [],
      email: "",
      issuspend: 0,
      dataShow: [],
      communityList: [],
      MerchantShow: [],
      suspendShow: [
        { issuspendId: 0, issuspendName: "No" },
        { issuspendId: 1, issuspendName: "Yes" },
      ],
      merchantId: "",
      messageError: "",
      setOpenValidation: false,
      openValidation: false,
      titleValidation: "",
      titleBody: "",
      showLoadingSubmit: false,
      openSuccess: false,
      openSuccessText: "",
      optionDataWebsite: [],
      selectWebsiteId: "",
      loginInfo: "",
      setOpenFromUbahPassword: false,
      submitLoading: false,
      password: "",
      confirmpass: "",
    };
  }

  componentDidMount = () => {
    this.getRoleData();
    this.doLoadRoleAccess();
    this.doLoadOption();
  };
  getRoleData = () => {
    let loginInfo = localStorage.getItem("loginInfo");
    loginInfo = JSON.parse(loginInfo);
    console.log(loginInfo);
    this.setState({
      loginInfo: loginInfo,
    });
  };

  doGetDetail = () => {
    this.props.doLoading();

    apiAdminGetDetail(this.state.id_user)
      .then((response, logintype) => {
        this.props.doLoading();

        let tmp = [];
        if (response.data.record.profilepic !== "") {
          tmp.push(response.data.record.profilepic);
        }

        const results = this.state.optionDataWebsite.filter(({ value: id1 }) =>
          response.data.record.info.some(({ id_agent: id2 }) => id2 === id1)
        );

        console.log(results);

        this.setState({ id_user: response.data.record.id_user });
        this.setState({ phoneno: response.data.record.phoneno });
        this.setState({ username: response.data.record.username });
        this.setState({ name: response.data.record.name });
        this.setState({ logintype: response.data.record.logintypeid });
        this.setState({ profilepic: tmp });
        this.setState({ email: response.data.record.email });
        this.setState({ issuspend: response.data.record.issuspend });
        this.setState({ selectWebsiteId: results });
        this.setState({ communityList: response.data.record.community });
      })
      .catch((error) => {
        this.props.doLoading();

        alert(error);
      });
  };

  doLoadRoleAccess = () => {
    apiLoginType()
      .then((res) => {
        this.setState({ dataShow: res.data.records });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doLoadOption = () => {
    apiOptionWebsite()
      .then((res) => {
        console.log(res);
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            this.setState({
              optionDataWebsite: data.records,
            });
          }
          this.doGetDetail();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doSubmitAdmin = () => {
    let params = {
      id_user: this.state.id_user,
      username: this.state.username,
      phoneno: this.state.phoneno,
      name: this.state.name,
      logintype: this.state.logintype,
      profilepic: this.state.profilepic,
      email: this.state.email,
      issuspend: this.state.issuspend,
      password: this.state.password,
      agentId: this.state.selectWebsiteId,
      community: this.state.communityList,
    };
    console.log(params);
    this.props.doLoading();
    this.setState({ showLoadingSubmit: true });
    apiAdminUpdate(params)
      .then((res) => {
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          this.setState({
            showLoadingSubmit: false,
            openSuccess: true,
            openSuccessText: "Data berhasil disimpan",
          });

          let paramsLog = {
            userId: this.state.loginInfo.id_user,
            description: "Ubah user " + this.state.username,
          };

          this.doLogActivityInsert(paramsLog);
        }
      })
      .catch((error) => {
        this.setState({ showLoadingSubmit: false });
        this.props.doLoading();
        console.log(error);
        alert(error);
      });
  };

  doLogActivityInsert = (params) => {
    apiLogActivityInsert(params)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  changeSelectMultiColumn = (logintypeid) => {
    this.setState({ logintype: logintypeid });
  };

  onUploadImage = (result) => {
    this.setState({ profilepic: result });
  };

  suspendHandleChange = (issuspend) => {
    this.setState({
      issuspend: issuspend,
    });
  };

  checkData = () => {
    const { username } = this.state;
    const { logintype } = this.state;

    if (username === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Username kosong?",
        titleBody: "Silahkan isi username",
      });
    } else if (logintype === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Tipe user kosong?",
        titleBody: "Silahkan pilih tipe user",
      });
    } else {
      this.doSubmitAdmin();
    }
  };

  onChangeAccessCommunity = (update) => {
    this.setState({ communityList: update });
  };

  changeMerchant = (merchantId) => {
    this.setState({ merchantId: merchantId });
  };

  handleChangeProject = (selectWebsiteId) => {
    this.setState({
      selectWebsiteId: selectWebsiteId,
    });
  };

  handleCloseValidationAlert = () => {
    this.setState({
      openValidation: false,
      titleValidation: "",
      titleBody: "",
    });
  };

  renderValidationAlert = () => {
    return (
      <Dialog
        open={this.state.openValidation}
        onClose={this.handleCloseValidationAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        fullWidth="xs"
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="label-title-alert">{this.state.titleValidation}</div>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <div className="title-body-alert"> {this.state.titleBody} </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton
            style={{ backgroundColor: "#f2f2f2" }}
            onClick={this.handleCloseValidationAlert}
          >
            <Close />
          </IconButton>
        </DialogActions>
      </Dialog>
    );
  };

  closeStackSuccess = () => {
    this.setState({
      openSuccess: false,
      openSuccessText: "",
    });
    this.props.history.push("/panel/user");
  };

  renderSuccess = () => {
    if (this.state.openSuccess === true) {
      setTimeout(() => this.closeStackSuccess(), 2000);

      return (
        <div style={{ margin: 10 }}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert
              variant="filled"
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  // onClick={() => this.reset()}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {this.state.openSuccessText}
            </Alert>
          </Stack>
        </div>
      );
    }
  };

  doUbahPassword = () => {
    this.setState({
      setOpenFromUbahPassword: true,
    });
  };

  handleCloseForm = () => {
    this.setState({
      setOpenFromUbahPassword: false,
      password: "",
      confirmpass: "",
    });
  };

  checkDataUbahPassword = () => {
    const { password, confirmpass } = this.state;

    if (password === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Password kosong?",
        titleBody: "Silahkan isi password",
      });
    } else if (password !== confirmpass) {
      this.setState({
        messageError: "Confirm password tidak sama.",
        setOpenValidation: true,
      });
      this.setState({
        openValidation: true,
        titleValidation: "Confirm password invalid?",
        titleBody: "Password dan Confirm Password harus sama dan sesuai",
      });
    } else {
      this.doSubmitUbahPassword();
    }
  };

  doSubmitUbahPassword = () => {
    this.setState({
      submitLoading: true,
    });
    let params = {
      id_user: this.state.id_user,
      password: this.state.password,
    };
    apiAdminUbahPassword(params)
      .then((res) => {
        this.setState({
          submitLoading: false,
        });
        let data = res.data;
        if (data.status === "OK") {
          this.handleCloseForm();
          this.setState({
            openValidation: true,
            titleValidation: "Alert",
            titleBody: "Ubah password berhasil.",
          });
          let paramsLog = {
            userId: this.state.loginInfo.id_user,
            description: "Ubah password " + this.state.username,
          };

          this.doLogActivityInsert(paramsLog);
        }
      })
      .catch((err) => {
        this.setState({
          submitLoading: false,
        });
        console.log(err);
      });
  };

  renderFormUbahPassword = () => {
    return (
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={this.state.setOpenFromUbahPassword}
        fullWidth="md"
        maxWidth="md"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography variant="h5" style={stylesDialog.title}>
              Ubah Password
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.doCloseAddMultipleBukaan()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <Box sx={{ flexGrow: 1 }} style={{ marginBottom: 60, marginTop: 20 }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography
                  for="name"
                  component="span"
                  variant="subtitle1"
                  style={{
                    // fontSize: 16,
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Password
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Input
                  autoComplete="off"
                  type="password"
                  name="password"
                  id="password"
                  value={this.state.password}
                  onChange={(event) =>
                    this.setState({ password: event.target.value })
                  }
                />
              </Grid>

              <Grid item xs={4}>
                <Typography
                  for="name"
                  component="span"
                  variant="subtitle1"
                  style={{
                    // fontSize: 16,
                    float: "left",
                    marginTop: 6,
                    textTransform: "capitalize",
                  }}
                >
                  Confirm Password
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Input
                  autoComplete="off"
                  type="password"
                  name="confirmpassword"
                  id="confirmpassword"
                  value={this.state.confirmpass}
                  onChange={(event) =>
                    this.setState({ confirmpass: event.target.value })
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#d0021b",
            }}
            startIcon={<Cancel />}
            onClick={() => this.handleCloseForm()}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Batal
            </Typography>
          </Button>
          &nbsp;&nbsp;
          <Button
            disabled={this.state.submitLoading}
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#004dcf",
            }}
            startIcon={<Lock />}
            onClick={() => this.checkDataUbahPassword()}
          >
            {this.state.submitLoading === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Ubah Password
              </Typography>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  render() {
    return (
      <div>
        <div className="page-header">
          <ButtonUI
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#000",
            }}
            startIcon={<ArrowBackIos />}
            onClick={() => this.props.history.push("/panel/user")}
          >
            <Typography
              variant="button"
              style={{
                fontSize: 12,
                color: "#fff",
                textTransform: "capitalize",
                marginLeft: -10,
              }}
            >
              Kembali
            </Typography>
          </ButtonUI>{" "}
          <Typography
            component="span"
            variant="h2"
            style={{
              fontSize: 16,
              color: "#000",
              fontWeight: "bold",
              textTransform: "capitalize",
              float: "right",
            }}
          >
            Ubah User
          </Typography>
          <span className="dash">&nbsp;&nbsp;</span>
        </div>
        <div className="box-container">
          <Box sx={{ flexGrow: 1 }}>
            <div className="telegram-manage-api-desktop">
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography
                    for="name"
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Username
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="username"
                    id="username"
                    value={this.state.username}
                    onChange={(event) =>
                      this.setState({ username: event.target.value })
                    }
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Tipe
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <SelectMultiColumn
                    width={"100%"}
                    value={this.state.logintype}
                    valueColumn={"logintypeid"}
                    showColumn={"logintypename"}
                    columns={["logintypename"]}
                    data={this.state.dataShow}
                    onChange={this.changeSelectMultiColumn}
                  />
                </Grid>

                {this.state.logintype === 2 ? (
                  <>
                    <Grid item xs={2}>
                      <Typography
                        component="span"
                        variant="subtitle1"
                        style={{
                          // fontSize: 16,
                          float: "left",
                          marginTop: 6,
                          textTransform: "capitalize",
                        }}
                      >
                        Project
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Select
                        isMulti
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Pilih Project"
                        value={this.state.selectWebsiteId}
                        onChange={this.handleChangeProject}
                        options={this.state.optionDataWebsite}
                      />
                    </Grid>
                  </>
                ) : (
                  <></>
                )}

                <Grid item xs={2}>
                  <Typography
                    for="name"
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Upload Photo
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <PictureUploader
                    onUpload={this.onUploadImage}
                    picList={this.state.profilepic}
                    picLimit={1}
                  ></PictureUploader>
                </Grid>

                <Grid item xs={2}>
                  <Typography
                    for="name"
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    {this.language.fieldsuspend}
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <SelectMultiColumn
                    width={"100%"}
                    value={this.state.issuspend}
                    valueColumn={"issuspendId"}
                    showColumn={"issuspendName"}
                    columns={["issuspendName"]}
                    data={this.state.suspendShow}
                    onChange={this.suspendHandleChange}
                  />
                </Grid>

                <Grid item xs={2}></Grid>
                <Grid item xs={10}>
                  <ButtonUI
                    variant="contained"
                    style={{
                      backgroundColor: "#0693e3",
                    }}
                    startIcon={<LockOpen />}
                    onClick={() => this.doUbahPassword()}
                  >
                    <Typography
                      variant="button"
                      style={{
                        color: "#fff",
                        textTransform: "capitalize",
                        marginLeft: -6,
                      }}
                    >
                      Ubah Password
                    </Typography>
                  </ButtonUI>
                </Grid>
              </Grid>
            </div>
          </Box>
          <br></br>
          <div className="form-button-container">
            <br></br>
            <ButtonUI
              variant="contained"
              style={{
                backgroundColor: "#d0021b",
              }}
              startIcon={<Cancel />}
              onClick={() => this.props.history.push("/panel/user")}
            >
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                  marginLeft: -6,
                }}
              >
                Batal
              </Typography>
            </ButtonUI>{" "}
            &nbsp;&nbsp;
            <ButtonUI
              variant="contained"
              style={{
                backgroundColor: "#004dcf",
              }}
              startIcon={<Save />}
              onClick={() => this.checkData()}
            >
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                  marginLeft: -6,
                }}
              >
                Simpan
              </Typography>
            </ButtonUI>{" "}
          </div>
        </div>
        {this.renderValidationAlert()}
        {this.renderSuccess()}
        {this.renderFormUbahPassword()}
      </div>
    );
  }
}
export default EditAdmin;
