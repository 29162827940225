import React, { Component } from "react";
import {
  Typography,
  Button,
  Grid,
  Box,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Slide,
  Stack,
  Alert,
  CircularProgress,
} from "@mui/material";

import { ArrowBackIos, Save, Cancel, Close } from "@mui/icons-material";
import { Input } from "reactstrap";
import {
  apiWhiteListUpdate,
  apiWhiteListGetId,
  apiOptionAgent,
} from "../../Service/api";
// import { uuidRandomId } from "../../../global";
// import moment from "moment";
import Select from "react-select";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class EditWhiteListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idWhiteList: props.match.params.idWhiteList,
      ipAddress: "",
      PIC: "",
      status: null,
      optionStatus: [
        { value: 0, label: "Disable" },
        { value: 1, label: "Enable" },
      ],
      openValidation: false,
      titleValidation: "",
      titleBody: "",
      showLoadingSubmit: false,
      openSuccess: false,
      openSuccessText: "",
      loginInfo: "",
      optionData: [],
      selectedAgent: "",
    };
  }

  componentDidMount = () => {
    this.getRoleData();
    this.doLoadOption();
  };

  doLoadOption = () => {
    apiOptionAgent()
      .then((res) => {
        // console.log(res);
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            this.setState({
              optionData: data.records,
            });
          }
        }
        this.doGetDetail(this.state.idWhiteList);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doGetDetail = (idWhiteList) => {
    this.props.doLoading();
    apiWhiteListGetId(idWhiteList)
      .then((res) => {
        // console.log(res);
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          let dataStatus = this.state.optionStatus.filter(
            (obj) => obj.value === data.record.status
          );

          const results = this.state.optionData.filter(({ value: id1 }) =>
            data.record.info.some(({ id_user: id2 }) => id2 === id1)
          );

          // console.log(results);

          this.setState({
            idWhiteList: data.record.id_whitelist,
            ipAddress: data.record.ip_address,
            status: dataStatus.length === 0 ? null : dataStatus[0],
            selectedAgent: results,
          });
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  getRoleData = () => {
    let loginInfo = localStorage.getItem("loginInfo");
    loginInfo = JSON.parse(loginInfo);
    // console.log(loginInfo);
    this.setState({
      loginInfo: loginInfo,
      PIC: loginInfo.id_user,
    });
  };

  doSubmit = () => {
    const { ipAddress, status, selectedAgent } = this.state;
    if (ipAddress === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Ip Address kosong?",
        titleBody: "Silahkan isi ip address",
      });
    } else if (selectedAgent === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Agent kosong?",
        titleBody: "Silahkan pilih agent",
      });
    } else if (status === null) {
      this.setState({
        openValidation: true,
        titleValidation: "Status kosong?",
        titleBody: "Silahkan pilih status whitelist",
      });
    } else {
      let params = {
        idWhiteList: this.state.idWhiteList,
        ipAddress: this.state.ipAddress,
        PIC: this.state.PIC,
        status: this.state.status.value,
        agentlist: this.state.selectedAgent,
      };
      // console.log(params);
      this.props.doLoading();
      this.setState({ showLoadingSubmit: true });
      apiWhiteListUpdate(params)
        .then((res) => {
          // console.log(res);
          this.props.doLoading();
          this.setState({
            showLoadingSubmit: false,
            openSuccess: true,
            openSuccessText: "Data berhasil disimpan",
          });
        })
        .catch((err) => {
          console.log(err);
          this.props.doLoading();
          this.setState({ showLoadingSubmit: false });
        });
    }
  };

  handleChangeStatus = (status) => {
    this.setState({
      status: status,
    });
  };

  handleCloseValidationAlert = () => {
    this.setState({
      openValidation: false,
      titleValidation: "",
      titleBody: "",
    });
  };

  renderValidationAlert = () => {
    return (
      <Dialog
        open={this.state.openValidation}
        onClose={this.handleCloseValidationAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        fullWidth="xs"
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="label-title-alert">{this.state.titleValidation}</div>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <div className="title-body-alert"> {this.state.titleBody} </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton
            style={{ backgroundColor: "#f2f2f2" }}
            onClick={this.handleCloseValidationAlert}
          >
            <Close />
          </IconButton>
        </DialogActions>
      </Dialog>
    );
  };

  closeStackSuccess = () => {
    this.setState({
      openSuccess: false,
      openSuccessText: "",
    });
    this.props.history.push("/panel/whitelist-ip");
  };

  renderSuccess = () => {
    if (this.state.openSuccess === true) {
      setTimeout(() => this.closeStackSuccess(), 2000);

      return (
        <div style={{ margin: 10 }}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert
              variant="filled"
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  // onClick={() => this.reset()}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {this.state.openSuccessText}
            </Alert>
          </Stack>
        </div>
      );
    }
  };

  handleChangeOption = (selectedAgent) => {
    this.setState({
      selectedAgent: selectedAgent,
    });
  };

  render() {
    return (
      <div>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper
                style={{
                  borderLeft: 6,
                  borderColor: "#cccccc",
                  backgroundColor: "#cccccc",
                  maxHeight: 100,
                  padding: 16,
                }}
              >
                <div className="page-header">
                  <Button
                    variant="contained"
                    size="small"
                    style={{
                      backgroundColor: "#000",
                    }}
                    startIcon={<ArrowBackIos />}
                    onClick={() =>
                      this.props.history.push("/panel/whitelist-ip")
                    }
                  >
                    <Typography
                      variant="button"
                      style={{
                        fontSize: 12,
                        color: "#fff",
                        textTransform: "capitalize",
                        marginLeft: -10,
                      }}
                    >
                      Kembali
                    </Typography>
                  </Button>{" "}
                  <Typography
                    component="span"
                    variant="h2"
                    style={{
                      fontSize: 16,
                      color: "#000",
                      fontWeight: "bold",
                      textTransform: "capitalize",
                      float: "right",
                    }}
                  >
                    Ubah Whitelist IP Address
                  </Typography>
                  <span className="dash">&nbsp;&nbsp;</span>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <br></br>
        <div className="box-container">
          <Box sx={{ flexGrow: 1 }}>
            <div className="">
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    IP Address
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="ipAddress"
                    id="ipAddress"
                    placeholder="Ex. 190.x.x.x"
                    value={this.state.ipAddress}
                    onChange={(event) =>
                      this.setState({ ipAddress: event.target.value })
                    }
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      // fontSize: 16,
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Agent
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Select
                    isMulti
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Pilih Agent"
                    value={this.state.selectedAgent}
                    onChange={this.handleChangeOption}
                    options={this.state.optionData}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Status
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Select
                    isClearable
                    classNamePrefix="select"
                    placeholder="Pilih Status"
                    value={this.state.status}
                    onChange={this.handleChangeStatus}
                    options={this.state.optionStatus}
                  />
                </Grid>
              </Grid>
            </div>
          </Box>
          <br></br>
          <div className="form-button-container">
            <br></br>
            <Button
              disabled={this.state.showLoadingSubmit}
              variant="contained"
              size="medium"
              style={{
                backgroundColor: "#d0021b",
              }}
              startIcon={<Cancel />}
              onClick={() => this.props.history.push("/panel/whitelist-ip")}
            >
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                  marginLeft: -6,
                }}
              >
                Batal
              </Typography>
            </Button>{" "}
            &nbsp;&nbsp;
            <Button
              disabled={this.state.showLoadingSubmit}
              variant="contained"
              size="medium"
              style={{
                backgroundColor: "#004dcf",
              }}
              startIcon={<Save />}
              onClick={() => this.doSubmit()}
            >
              {this.state.showLoadingSubmit === true ? (
                <CircularProgress style={{ color: "#fff" }} size={24} />
              ) : (
                <Typography
                  variant="button"
                  style={{
                    color: "#fff",
                    textTransform: "capitalize",
                    marginLeft: -6,
                  }}
                >
                  Simpan
                </Typography>
              )}
            </Button>{" "}
          </div>
        </div>
        <br></br>
        {this.renderValidationAlert()}
        {this.renderSuccess()}
      </div>
    );
  }
}
export default EditWhiteListPage;
