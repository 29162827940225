import React, { Component } from "react";
import {
  apiAdminList,
  apiAdminDelete,
  apiAdminDeleteAll,
} from "../../Service/api";
import ReactTable from "react-table";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-table/react-table.css";
import { activeLanguage } from "../../../config";
import { getLanguage } from "../../../languages";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  DialogActions,
  DialogContent,
  IconButton,
  DialogContentText,
  Stack,
  Alert,
  Checkbox,
  DialogTitle,
  Slide,
  CircularProgress,
} from "@mui/material";
import {
  Refresh,
  Delete,
  Close,
  Cancel,
  Check,
  Feed,
  Public,
  AddBox,
  DeleteOutline,
} from "@mui/icons-material";

const stylesListComent = {
  inline: {
    display: "inline",
  },
};

const stylesListDialog = {
  inline: {
    display: "inline",
  },
};

const stylesDialog = {
  appBar: {
    position: "relative",
    backgroundColor: "#03101e",
  },
  title: {
    marginLeft: 0,
    flex: 1,
    fontSize: 16,
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class ListAdmin extends Component {
  constructor(props) {
    super(props);
    this.reactTable = React.createRef();
    this.globallang = getLanguage(activeLanguage, "global");
    this.language = getLanguage(activeLanguage, "listadmin");
    this.state = {
      tableData: [],
      filter: "",
      groupShow: [],
      selectedGroupId: null,
      openAlertDelete: false,
      itemDeleted: {},
      setOpenAdd: false,
      setOpenEdit: false,
      item_detail: {},
      selectAll: false,
      checked: [],
      selectRowData: [],
      openSelectedRow: false,
      openAlertDeleteAll: false,
      setOpenDialogImport: false,
      dataJson: [],
      uploadLoading: false,
      file: null,
      openValidation: false,
      titleValidation: "",
      titleBody: "",
      showLoadingSubmit: false,
      loginInfo: "",
    };
  }

  componentDidMount = () => {
    this.doLoadData();
  };

  doLoadData = () => {
    this.props.doLoading();
    apiAdminList(this.state.filter)
      .then((res) => {
        this.props.doLoading();
        // console.log(res);
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            let temp = data.records;
            for (var i = 0; i < temp.length; i++) {
              temp[i].no = i + 1;
            }
            // console.log(temp);
            this.setState({ tableData: temp });
          } else {
            this.setState({ tableData: [] });
          }
        } else {
          this.setState({ tableData: [] });
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  doDeleteItem = (id_user) => {
    this.setState({
      showLoadingSubmit: true,
    });
    this.props.doLoading();
    apiAdminDelete(id_user)
      .then((res) => {
        this.setState({
          showLoadingSubmit: false,
        });
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          this.setState({
            openSuccess: true,
            openSuccessText: "Data berhasil dihapus",
            openAlertDelete: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          showLoadingSubmit: false,
        });
        this.props.doLoading();
        console.log(err);
      });
  };

  pressDeleteAll = () => {
    let params = {
      item_delete: this.state.selectRowData,
    };
    this.setState({
      showLoadingSubmit: true,
    });
    this.props.doLoading();
    apiAdminDeleteAll(params)
      .then((res) => {
        this.setState({
          showLoadingSubmit: false,
        });
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          this.setState({
            openSuccess: true,
            openSuccessText: "Data berhasil dihapus.",
            openAlertDeleteAll: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          showLoadingSubmit: false,
        });
        this.props.doLoading();
        console.log(err);
      });
  };

  pressReset = () => {
    // console.log("reset");
    this.setState({
      filter: "",
      selectedGroupId: null,
      openSuccess: false,
      openSuccessText: "",
      selectAll: false,
      selectRowData: [],
      checked: [],
    });

    this.doLoadData();
  };

  handleCloseDetail = () => {
    this.setState({
      setOpenAdd: false,
      setOpenEdit: false,
    });
  };

  addNew = () => {
    this.props.history.push("/panel/add-user");
  };

  doRowEdit = (row) => {
    this.props.history.push("/panel/edit-user/" + row.id_user);
  };

  doRowDeleteInfo = (item) => {
    this.setState({
      openAlertDelete: true,
      itemDeleted: item,
    });
  };

  handleCloseItemInfo = () => {
    this.setState({
      openAlertDelete: false,
      itemDeleted: {},
      openAlertDeleteAll: false,
      selectRowData: [],
    });
  };

  toggleSelectAll = (e) => {
    let tmp = this.state.tableData;
    for (let i = 0; i < tmp.length; i++) {
      tmp[i].isChecked = e.target.checked;
    }
    // console.log(tmp);
    // console.log(e.target.checked);
    this.setState({ tableData: tmp, selectAll: e.target.checked });
    var selectAll = !this.state.selectAll;
    // console.log(selectAll);
    this.setState({ selectAll: selectAll });
    var checkedCopy = [];
    tmp.forEach(function (e, index) {
      checkedCopy.push(selectAll);
    });
    // console.log(checkedCopy);
    this.setState({
      checked: checkedCopy,
    });

    var selectRow = [];
    tmp.forEach((val, idx) => {
      if (val.isChecked === true) {
        selectRow.push(val);
      }
    });

    // console.log(selectRow);
    this.setState({
      selectRowData: selectRow,
    });
  };

  handleCheck = (e, i) => {
    let tmp = this.state.tableData;
    tmp[i].isChecked = e.target.checked;
    // console.log(tmp);
    this.setState({ tableDisplay: tmp });
    this.setState({ checked: tmp[i].isChecked });

    var checkedCopy = this.state.checked;
    checkedCopy[i] = !this.state.checked[i];
    if (checkedCopy[i] === false) {
      this.setState({ selectAll: false });
    }
    this.setState({
      checked: checkedCopy,
    });

    var selectRow = [];
    tmp.forEach((val, idx) => {
      if (val.isChecked === true) {
        selectRow.push(val);
      }
    });
    this.setState({
      selectRowData: selectRow,
    });
  };

  renderRemoveItemAll = () => {
    return (
      <Dialog
        open={this.state.openAlertDeleteAll}
        onClose={this.handleCloseItemInfo}
        aria-labelledby="customized-dialog-title"
        fullWidth="sm"
        maxWidth="sm"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography
              component="span"
              variant="h2"
              style={stylesDialog.title}
            >
              Hapus User?
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseItemInfo()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ marginTop: 10 }}>
          <DialogContentText id="alert-dialog-description">
            <Typography
              component="span"
              variant="body2"
              style={(stylesListDialog.inline, { fontSize: 16, color: "#333" })}
            >
              Hapus {this.state.selectRowData.length} data user?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#808080",
            }}
            startIcon={<Cancel />}
            onClick={this.handleCloseItemInfo}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Batal
            </Typography>
          </Button>{" "}
          <Button
            disabled={this.state.showLoadingSubmit}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#004dcf",
            }}
            startIcon={this.state.showLoadingSubmit === true ? null : <Check />}
            onClick={() => this.pressDeleteAll()}
          >
            {this.state.showLoadingSubmit === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Ya
              </Typography>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderRemoveItemInfo = () => {
    let item = this.state.itemDeleted;
    return (
      <Dialog
        open={this.state.openAlertDelete}
        onClose={this.handleCloseItemInfo}
        aria-labelledby="customized-dialog-title"
        fullWidth="sm"
        maxWidth="sm"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography
              component="span"
              variant="h2"
              style={stylesDialog.title}
            >
              Hapus user?
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseItemInfo()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ marginTop: 10 }}>
          <DialogContentText id="alert-dialog-description">
            <Typography
              component="span"
              variant="body2"
              style={(stylesListDialog.inline, { fontSize: 16, color: "#333" })}
            >
              Hapus data user {item.name}?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#808080",
            }}
            startIcon={<Cancel />}
            onClick={this.handleCloseItemInfo}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Batal
            </Typography>
          </Button>{" "}
          <Button
            disabled={this.state.showLoadingSubmit}
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#004dcf",
            }}
            startIcon={this.state.showLoadingSubmit === true ? null : <Check />}
            onClick={() => this.doDeleteItem(item.id_user)}
          >
            {this.state.showLoadingSubmit === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Ya
              </Typography>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  closeStackSuccess = () => {
    this.setState({
      openSuccess: false,
      openSuccessText: "",
    });
  };

  renderSuccess = () => {
    if (this.state.openSuccess === true) {
      setTimeout(() => this.pressReset(), 2000);

      return (
        <div style={{ margin: 10 }}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert
              variant="filled"
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  // onClick={() => this.reset()}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {this.state.openSuccessText}
            </Alert>
          </Stack>
        </div>
      );
    }
  };

  handleCloseValidationAlert = () => {
    this.setState({
      openValidation: false,
      titleValidation: "",
      titleBody: "",
    });
  };

  renderValidationAlert = () => {
    return (
      <Dialog
        open={this.state.openValidation}
        onClose={this.handleCloseValidationAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        fullWidth="xs"
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="label-title-alert">{this.state.titleValidation}</div>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <div className="title-body-alert"> {this.state.titleBody} </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton
            style={{ backgroundColor: "#f2f2f2" }}
            onClick={this.handleCloseValidationAlert}
          >
            <Close />
          </IconButton>
        </DialogActions>
      </Dialog>
    );
  };

  render() {
    return (
      <Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper
                style={{
                  borderLeft: 6,
                  borderColor: "#07213c",
                  backgroundColor: "#07213c",
                  maxHeight: 100,
                  padding: 16,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    color: "white",
                  }}
                >
                  <Public style={{ marginRight: 10 }} />
                  <Typography
                    component="span"
                    variant="h1"
                    style={
                      (stylesListComent.inline,
                      {
                        fontSize: 20,
                        color: "#FFF",
                        fontWeight: "bold",
                      })
                    }
                  >
                    User
                  </Typography>
                </div>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper
                style={{
                  borderLeft: 6,
                  borderColor: "#2f55a2",
                  backgroundColor: "#cccccc",
                  maxHeight: 100,
                  padding: 16,
                  paddingBottom: 24,
                }}
              >
                <div className="contentDate">
                  <div style={{ marginRight: 16 }}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#4caf50",
                      }}
                      startIcon={<AddBox />}
                      onClick={() => this.addNew()}
                    >
                      <Typography
                        variant="button"
                        style={{
                          fontSize: 14,
                          color: "#fff",
                          textTransform: "capitalize",
                        }}
                      >
                        Tambah
                      </Typography>
                    </Button>
                  </div>
                  <div style={{ marginRight: 0 }}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#1273DE",
                      }}
                      startIcon={<Refresh />}
                      onClick={() => this.pressReset()}
                    >
                      <Typography
                        variant="button"
                        style={{
                          fontSize: 14,
                          color: "#fff",
                          textTransform: "capitalize",
                        }}
                      >
                        Refresh
                      </Typography>
                    </Button>
                  </div>

                  {this.state.selectRowData.length > 0 ? (
                    <div style={{ marginRight: 0, marginLeft: 16 }}>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "#ff0000",
                        }}
                        startIcon={<DeleteOutline />}
                        onClick={() =>
                          this.setState({ openAlertDeleteAll: true })
                        }
                      >
                        <Typography
                          variant="button"
                          style={{
                            fontSize: 14,
                            color: "#fff",
                            textTransform: "capitalize",
                          }}
                        >
                          Hapus ({this.state.selectRowData.length})
                        </Typography>
                      </Button>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <br></br>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        {this.renderSuccess()}
        <br></br>
        <div className="box-container">
          <ReactTable
            ref={(r) => (this.reactTable = r)}
            data={this.state.tableData}
            columns={[
              {
                Header: (
                  <Checkbox
                    style={{
                      width: 30,
                      height: 30,
                      color: "#3a6cf6",
                    }}
                    onChange={(e) => this.toggleSelectAll(e)}
                    checked={this.state.selectAll}
                    inputProps={{
                      "aria-labelledby": "checkbox-list-secondary-label",
                    }}
                  />
                ),
                Cell: (row) => (
                  <Checkbox
                    style={{
                      width: 30,
                      height: 30,
                      color: "#3a6cf6",
                    }}
                    defaultChecked={this.state.checked[row.index]}
                    checked={this.state.checked[row.index]}
                    onChange={(e) => this.handleCheck(e, row.index)}
                    inputProps={{
                      "aria-labelledby": "checkbox-list-secondary-label",
                    }}
                  />
                ),
                headerStyle: { fontWeight: "bold" },
                sortable: false,
                filterable: true,
                style: { textAlign: "center" },
                width: 100,
              },
              {
                Header: "No",
                headerStyle: { fontWeight: "bold" },
                accessor: "no",
                style: { textAlign: "center" },
                width: 100,
              },
              {
                Header: "Username",
                headerStyle: { fontWeight: "bold" },
                accessor: "username",
                style: { textAlign: "center" },
              },
              {
                Header: "Tipe",
                headerStyle: { fontWeight: "bold" },
                accessor: "logintypename",
                style: { textAlign: "center" },
              },

              {
                Header: "Last Login",
                headerStyle: { fontWeight: "bold" },
                accessor: "lastlogin",
                style: { textAlign: "center" },
              },
              {
                Header: "Last IP",
                headerStyle: { fontWeight: "bold" },
                accessor: "last_ip",
                style: { textAlign: "center" },
              },

              {
                Header: "Suspend",
                headerStyle: { fontWeight: "bold" },
                accessor: "issuspend",
                style: { textAlign: "center" },
                Cell: (e) =>
                  e.original.issuspend === 0
                    ? this.globallang["no"]
                    : this.globallang["yes"],
              },
              {
                Header: "Action",
                headerStyle: { fontWeight: "bold" },
                accessor: "",
                style: { textAlign: "center" },
                width: 200,
                Cell: (e) => (
                  <>
                    <div>
                      <Button
                        variant="contained"
                        size="small"
                        style={{
                          backgroundColor: "#3f51b5",
                        }}
                        startIcon={<Feed />}
                        onClick={() => this.doRowEdit(e.original)}
                      >
                        <Typography
                          variant="button"
                          style={{
                            fontSize: 14,
                            color: "#fff",
                            textTransform: "capitalize",
                          }}
                        >
                          Ubah
                        </Typography>
                      </Button>
                      &nbsp;
                      <Button
                        variant="contained"
                        size="small"
                        style={{
                          backgroundColor: "#ff0000",
                        }}
                        startIcon={<Delete />}
                        onClick={() => this.doRowDeleteInfo(e.original)}
                      >
                        <Typography
                          variant="button"
                          style={{
                            fontSize: 14,
                            color: "#fff",
                            textTransform: "capitalize",
                          }}
                        >
                          Hapus
                        </Typography>
                      </Button>
                    </div>
                  </>
                ),
              },
            ]}
            style={{ backgroundColor: "#f2f2f2" }}
            filterable
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id])
                .toLowerCase()
                .includes(filter.value.toLowerCase())
            }
            defaultPageSize={5}
          />
        </div>
        {this.renderValidationAlert()}
        {this.renderRemoveItemAll()}
        {this.renderRemoveItemInfo()}
      </Box>
    );
  }
}
export default ListAdmin;
