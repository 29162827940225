import React, { Component } from "react";
import {
  apiOptionWebsite,
  apiBannerList,
  apiBannerInsertUpdate,
  apiLogActivityInsert,
} from "../../Service/api";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  Alert,
  Stack,
  IconButton,
} from "@mui/material";
import {
  AutoAwesomeMosaic,
  PhotoCamera,
  Save,
  Close,
} from "@mui/icons-material";
import Select from "react-select";
import { DropzoneDialog } from "material-ui-dropzone";
import { Container, Row, Col, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const stylesListComent = {
  inline: {
    display: "inline",
  },
};

const stylesDialog = {
  appBar: {
    position: "relative",
    backgroundColor: "#0d47a1",
  },
  title: {
    marginLeft: 0,
    flex: 1,
    fontSize: 16,
  },
};

class BannerPage extends Component {
  constructor(props) {
    super(props);
    this.reactTable = React.createRef();
    this.fileExcel = React.createRef();
    this.state = {
      tableData: [],
      tableDisplay: [],
      filter: "",
      groupShow: [],
      selectedGroupId: null,
      openAlertDelete: false,
      itemDeleted: {},
      setOpenAdd: false,
      setOpenEdit: false,
      item_detail: {},
      selectAll: false,
      checked: [],
      selectRowData: [],
      openSelectedRow: false,
      openAlertDeleteAll: false,
      setOpenDialogImport: false,
      dataJson: [],
      uploadLoading: false,
      file: null,
      openValidation: false,
      titleValidation: "",
      titleBody: "",
      showLoadingSubmit: false,
      selectWebsiteId: null,
      optionDataWebsite: [],
      bannerpic: [],
      bannerpicLimit: 20,
      projectName: "",
      projectUrl: "",
      agentId: "",
      openSuccess: false,
      openSuccessText: "",
      loginInfo: "",
    };
  }

  componentWillMount() {}

  componentDidMount() {
    this.getRoleData();
  }

  getRoleData = () => {
    let loginInfo = localStorage.getItem("loginInfo");
    loginInfo = JSON.parse(loginInfo);
    // console.log(loginInfo);
    this.setState({
      loginInfo: loginInfo,
    });
    this.doLoadOption();
  };

  doLoadOption = () => {
    apiOptionWebsite()
      .then((res) => {
        // console.log(res);
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            if (this.state.loginInfo.logintype === 2) {
              const results = data.records.filter(({ value: id1 }) =>
                this.state.loginInfo.info.some(
                  ({ id_agent: id2 }) => id2 === id1
                )
              );

              // console.log(results);
              this.setState({
                optionDataWebsite: results,
                selectWebsiteId: results[0],
                projectName: results[0].label,
                projectUrl: results[0].url,
                agentId: results[0].value,
              });
              this.doLoadBanner(results[0].value);
            } else {
              this.setState({
                optionDataWebsite: data.records,
                selectWebsiteId: data.records[0],
                projectName: data.records[0].label,
                projectUrl: data.records[0].url,
                agentId: data.records[0].value,
              });
              this.doLoadBanner(data.records[0].value);
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doLoadBanner = (agentId) => {
    this.props.doLoading();
    apiBannerList(agentId)
      .then((res) => {
        this.props.doLoading();
        // console.log(res);
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            this.setState({
              bannerpic: data.records,
            });
          } else {
            this.setState({
              bannerpic: [],
            });
          }
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  doSubmit = () => {
    let params = {
      agentId: this.state.agentId,
      bannerpic: this.state.bannerpic,
    };
    this.props.doLoading();
    apiBannerInsertUpdate(params)
      .then((res) => {
        this.props.doLoading();
        // console.log(res);
        let data = res.data;
        if (data.status === "OK") {
          this.setState({
            openSuccess: true,
            openSuccessText: "Data banner berhasil disimpan.",
          });

          let paramsLog = {
            userId: this.state.loginInfo.id_user,
            description:
              "Insert/Update Banner project " + this.state.projectName,
          };

          this.doLogActivityInsert(paramsLog);

          this.doLoadBanner(this.state.agentId);
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  doLogActivityInsert = (params) => {
    apiLogActivityInsert(params)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  pressReset = () => {
    // console.log("reset");
    this.setState({
      filter: "",
      selectedGroupId: null,
      openSuccess: false,
      openSuccessText: "",
      selectAll: false,
      selectRowData: [],
      checked: [],
    });
  };

  handleChangeWebsite = (selectWebsiteId) => {
    this.setState({
      selectWebsiteId: selectWebsiteId,
      projectName: selectWebsiteId.label,
      projectUrl: selectWebsiteId.url,
      agentId: selectWebsiteId.value,
    });
    this.doLoadBanner(selectWebsiteId.value);
  };

  onUploadImage = (result) => {
    this.setState({ bannerpic: result });
  };

  handleClose() {
    // console.log("cek");
    this.setState({
      open: false,
    });
  }

  handleSave(files) {
    // console.log("cek", files);
    for (let i = 0; i < files.length; i++) {
      let file = files[i];

      this.getImage(file);
    }
    this.setState({
      files: files,
      open: false,
    });
  }

  getImage = (file) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      let lists = this.state.bannerpic;
      lists.push(e.target.result);
      // console.log("cek bannerpic", lists);
      this.setState({ bannerpic: lists });
    };
    reader.readAsDataURL(file);
  };

  handleOpen() {
    this.setState({
      open: true,
    });
  }

  renderAmbilBannerPic = () => {
    if (
      this.state.bannerpic.length === 0 ||
      this.state.bannerpic.length < this.state.bannerpicLimit
    ) {
      return (
        <>
          <input style={{ display: "none" }} id="contained-button-file" />
          <label htmlFor="contained-button-file">
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleOpen.bind(this)}
              component="span"
              style={{
                backgroundColor: "#2e6da4",
              }}
              startIcon={<PhotoCamera />}
            >
              <Typography
                variant="subtitle1"
                style={
                  (stylesDialog.title,
                  {
                    color: "#fff",
                    fontSize: 15,
                    textTransform: "capitalize",
                  })
                }
              >
                Ambil Banner
              </Typography>
            </Button>
          </label>{" "}
          <br></br>
          <span style={{ color: "red", fontSize: 12 }}>
            * Banner maximal size 1 MB dan limit 20 item{" "}
          </span>
          <DropzoneDialog
            acceptedFiles={["image/*"]}
            dropzoneText="Seret dan lepas gambar di sini atau klik"
            open={this.state.open}
            onSave={this.handleSave.bind(this)}
            onClose={this.handleClose.bind(this)}
            maxFileSize={1048576}
            filesLimit={this.state.bannerpicLimit}
            showPreviews={false}
            showPreviewsInDropzone={true}
          />
        </>
      );
    }
  };

  renderPicture() {
    if (this.state.bannerpic.length > 0) {
      return (
        <Container>
          <Row>
            {this.state.bannerpic.map((img, i) => (
              <Col xs="6" sm="4" md="3" className="pic-col" key={i}>
                <img src={img} alt="uploadpic" />
                <div className="delete">
                  <FontAwesomeIcon
                    icon="trash"
                    onClick={() => this.removePicture(i)}
                  />
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      );
    }
  }

  removePicture = (idx) => {
    let lists = this.state.bannerpic;
    lists.splice(idx, 1);
    this.setState({ bannerpic: lists });
  };

  renderSuccess = () => {
    if (this.state.openSuccess === true) {
      setTimeout(() => this.reset(), 1000);

      return (
        <div style={{ margin: 10 }}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert
              variant="filled"
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  // onClick={() => this.reset()}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {this.state.openSuccessText}
            </Alert>
          </Stack>
        </div>
      );
    }
  };

  reset = () => {
    this.setState({ openSuccess: false, openSuccessText: "" });
  };

  render() {
    return (
      <Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper
                style={{
                  borderLeft: 6,
                  borderColor: "#07213c",
                  backgroundColor: "#07213c",
                  maxHeight: 100,
                  padding: 16,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    color: "white",
                  }}
                >
                  <AutoAwesomeMosaic style={{ marginRight: 10 }} />
                  <Typography
                    component="span"
                    variant="h1"
                    style={
                      (stylesListComent.inline,
                      {
                        fontSize: 20,
                        color: "#FFF",
                        fontWeight: "bold",
                      })
                    }
                  >
                    Banner
                  </Typography>
                </div>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper
                style={{
                  borderLeft: 6,
                  borderColor: "#2f55a2",
                  backgroundColor: "#cccccc",
                  maxHeight: 100,
                  padding: 16,
                  paddingBottom: 24,
                }}
              >
                <div className="contentDate">
                  <div style={{ marginRight: 0, width: 350 }}>
                    <Select
                      classNamePrefix="select"
                      placeholder="Pilih Website"
                      value={this.state.selectWebsiteId}
                      onChange={this.handleChangeWebsite}
                      options={this.state.optionDataWebsite}
                    />
                  </div>
                </div>
                <br></br>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <br></br>
        <div className="box-container">
          <Box sx={{ flexGrow: 1 }}>
            <div className="">
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Project
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="projectName"
                    id="projectName"
                    value={this.state.projectName}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Url
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Input
                    autoComplete="off"
                    type="text"
                    name="projectUrl"
                    id="projectUrl"
                    value={this.state.projectUrl}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={{
                      float: "left",
                      marginTop: 6,
                      textTransform: "capitalize",
                    }}
                  >
                    Banner
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Card variant="outlined">
                    <CardContent>
                      {this.renderAmbilBannerPic()}
                      {this.renderPicture()}
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </Box>
          <br></br>
          <div className="form-button-container">
            <br></br>
            <Button
              variant="contained"
              size="medium"
              style={{
                backgroundColor: "#004dcf",
              }}
              startIcon={<Save />}
              onClick={() => this.doSubmit()}
            >
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                  marginLeft: -6,
                }}
              >
                Simpan
              </Typography>
            </Button>{" "}
          </div>
        </div>
        {this.renderSuccess()}
      </Box>
    );
  }
}
export default BannerPage;
