import React, { Component } from "react";
import { apiLogActivity } from "../../Service/api";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Box, Paper, Grid, Typography, Button } from "@mui/material";
import { FilterAlt, HorizontalSplit } from "@mui/icons-material";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import addDays from "date-fns/addDays";
import moment from "moment";
import "moment/locale/id"; // without this line it didn't work
moment.locale("id");

const stylesListComent = {
  inline: {
    display: "inline",
  },
};

class LogActivityPage extends Component {
  constructor(props) {
    super(props);
    this.reactTable = React.createRef();
    this.fileExcel = React.createRef();
    this.state = {
      tableData: [],
      tableDisplay: [],
      loginInfo: "",
      startDate: moment(),
      endDate: moment(),
    };
  }

  componentWillMount() {}

  componentDidMount() {
    this.getRoleData();
  }

  getRoleData = () => {
    let loginInfo = localStorage.getItem("loginInfo");
    loginInfo = JSON.parse(loginInfo);
    // console.log(loginInfo);
    this.setState({
      loginInfo: loginInfo,
    });

    let resultDateActive = new Date();
    resultDateActive.setDate(resultDateActive.getDate() - 7);
    var back7Day = moment(resultDateActive).format("YYYY-MM-DD");

    let params = {
      startDate: back7Day,
      endDate: this.state.endDate.clone().startOf("day").format("YYYY-MM-DD"),
    };
    // console.log(params);

    this.doLoadData(params);
  };

  doLoadData = (params) => {
    this.props.doLoading();
    apiLogActivity(params)
      .then((res) => {
        this.props.doLoading();
        // console.log(res);
        let data = res.data;
        if (data.status === "OK") {
          if (data.records.length > 0) {
            let temp = data.records;
            this.setState({ tableData: temp });
          } else {
            this.setState({ tableData: [] });
          }
        } else {
          this.setState({ tableData: [] });
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  setStartDate = (date) => {
    this.setState({ startDate: date });
    this.setState({ endDate: moment() });
  };
  setEndDate = (date) => {
    this.setState({ endDate: date });
  };

  doFilter = () => {
    let params = {
      startDate: this.state.startDate
        .clone()
        .startOf("day")
        .format("YYYY-MM-DD"),
      endDate: this.state.endDate.clone().startOf("day").format("YYYY-MM-DD"),
    };
    // console.log(params);
    this.doLoadData(params);
  };

  render() {
    return (
      <Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper
                style={{
                  borderLeft: 6,
                  borderColor: "#07213c",
                  backgroundColor: "#07213c",
                  maxHeight: 100,
                  padding: 16,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    color: "white",
                  }}
                >
                  <HorizontalSplit style={{ marginRight: 10 }} />
                  <Typography
                    component="span"
                    variant="h1"
                    style={
                      (stylesListComent.inline,
                      {
                        fontSize: 20,
                        color: "#FFF",
                        fontWeight: "bold",
                      })
                    }
                  >
                    Log Activity
                  </Typography>
                </div>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper
                style={{
                  borderLeft: 6,
                  borderColor: "#2f55a2",
                  backgroundColor: "#cccccc",
                  maxHeight: 100,
                  padding: 16,
                  paddingBottom: 24,
                }}
              >
                <div className="contentDate">
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={
                      (stylesListComent.inline,
                      {
                        marginRight: 16,
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        alignSelf: "center",
                        color: "#07213c",
                        fontWeight: "bold",
                      })
                    }
                  >
                    Start Date:
                  </Typography>
                  <div style={{ marginRight: 16 }}>
                    <DatePicker
                      maxDate={addDays(new Date(), 30)}
                      selected={this.state.startDate}
                      onChange={(date) => this.setStartDate(date)}
                      selectsStart
                      startDate={this.state.startDate}
                      endDate={this.state.startDate}
                      className="datefilter"
                    />
                  </div>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    style={
                      (stylesListComent.inline,
                      {
                        marginRight: 16,
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        alignSelf: "center",
                        color: "#07213c",
                        fontWeight: "bold",
                      })
                    }
                  >
                    End Date:
                  </Typography>
                  <div style={{ marginRight: 16 }}>
                    <DatePicker
                      // maxDate={addDays(new Date(), 31)}
                      selected={this.state.endDate}
                      onChange={(date) => this.setEndDate(date)}
                      selectsEnd
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      minDate={this.state.startDate}
                      maxDate={this.state.endDate}
                      className="datefilter"
                    />
                  </div>

                  <div style={{ marginRight: 0 }}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#1273DE",
                      }}
                      startIcon={<FilterAlt />}
                      onClick={() => this.doFilter()}
                    >
                      <Typography
                        variant="button"
                        style={{
                          fontSize: 14,
                          color: "#fff",
                          textTransform: "capitalize",
                        }}
                      >
                        Filter
                      </Typography>
                    </Button>
                  </div>
                </div>
                <br></br>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <br></br>
        <div className="box-container">
          <ReactTable
            ref={(r) => (this.reactTable = r)}
            data={this.state.tableData}
            columns={[
              {
                Header: "Last Timestamp",
                headerStyle: { fontWeight: "bold" },
                accessor: "last_timestamp",
                style: { textAlign: "center" },
              },
              {
                Header: "Description",
                headerStyle: { fontWeight: "bold" },
                accessor: "description",
                style: { textAlign: "left" },
              },

              {
                Header: "PIC",
                headerStyle: { fontWeight: "bold" },
                accessor: "nama",
                style: { textAlign: "center" },
              },

              {
                Header: "Type",
                headerStyle: { fontWeight: "bold" },
                accessor: "type",
                style: { textAlign: "center" },
              },
            ]}
            style={{ backgroundColor: "#f2f2f2" }}
            filterable
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id])
                .toLowerCase()
                .includes(filter.value.toLowerCase())
            }
            defaultPageSize={5}
          />
        </div>
      </Box>
    );
  }
}
export default LogActivityPage;
