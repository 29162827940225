import React, { Component } from "react";
import "./Login.style.css";
import {
  Button,
  Typography,
  Grid,
  Paper,
  Box,
  TextField,
  Link,
  CssBaseline,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  apiLogin,
  apiGetIp,
  apiWhiteListAvailable,
  apiUpdateIp,
} from "../../Service/api";

const theme = createTheme();

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="">
        DDD
      </Link>
      {new Date().getFullYear()}
      {". Version: 1.0.4"}
    </Typography>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      userPassword: "",
      errorMessage: "",
      openValidation: false,
      titleValidation: "",
      titleBody: "",
      dataIPAvailable: [],
      showLoadingSubmit: false,
    };
  }

  componentDidMount = () => {
    this.doLoadAvailableIP();
  };

  componentWillUnmount = () => {};

  doLoadAvailableIP = () => {
    apiWhiteListAvailable()
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
          this.setState({
            dataIPAvailable: data.records,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doGetIP = (record, params) => {
    this.setState({
      showLoadingSubmit: true,
    });
    let { match } = this.props;
    apiGetIp()
      .then((res) => {
        this.setState({
          showLoadingSubmit: false,
        });
        let data = res.data;
        // console.log(data);
        let compareListIp = this.state.dataIPAvailable.filter(
          (obj) => obj.ip_address === data.IPv4
        );

        // console.log("compareListIp: ", compareListIp);

        if (compareListIp.length > 0) {
          let compareListAgent = compareListIp[0].info.filter(
            (obj) => obj.username === this.state.userName
          );

          // console.log("compareListAgent: ", compareListAgent);

          if (compareListAgent.length > 0) {
            apiUpdateIp(params)
              .then((res) => {
                // console.log(res);
              })
              .catch((err) => {
                console.log(err);
              });

            localStorage.setItem("loginInfo", JSON.stringify(record));
            this.props.history.replace({
              pathname: `${match.path}panel/dashboard`,
              state: { loginInfo: record },
            });
          } else {
            this.setState({
              openValidation: true,
              titleValidation: "Warning",
              titleBody: "IP Address Blacklist",
            });
          }
        } else {
          this.setState({
            openValidation: true,
            titleValidation: "Warning",
            titleBody: "IP Address Blacklist",
          });
        }

        // if (compareListIp.length > 0) {
        //   apiUpdateIp(params)
        //     .then((res) => {
        //       // console.log(res);
        //     })
        //     .catch((err) => {
        //       console.log(err);
        //     });

        //   localStorage.setItem("loginInfo", JSON.stringify(record));
        //   this.props.history.replace({
        //     pathname: `${match.path}panel/dashboard`,
        //     state: { loginInfo: record },
        //   });
        // } else {
        //   this.setState({
        //     openValidation: true,
        //     titleValidation: "Warning",
        //     titleBody: "IP Address Blacklist",
        //   });
        // }
      })
      .catch((err) => {
        this.setState({
          showLoadingSubmit: false,
        });
        console.log(err);
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    this.setState({
      userName: data.get("username"),
      userPassword: data.get("password"),
    });

    if (data.get("username") === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Username Kosong?",
        titleBody: "Silahkan isi Username",
      });
    } else if (data.get("password") === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Password Kosong?",
        titleBody: "Silahkan isi Password",
      });
    } else {
      let params = {
        userName: data.get("username"),
        userPassword: data.get("password"),
      };
      // console.log(params);
      let { match } = this.props;
      this.setState({
        showLoadingSubmit: true,
      });
      this.setState({ errorMessage: "", userPassword: "" });
      apiLogin(params)
        .then((res) => {
          this.setState({
            showLoadingSubmit: false,
          });
          // console.log(res);
          let data = res.data;
          if (data.status === "ok") {
            if (data.record.logintype === 1) {
              localStorage.setItem("loginInfo", JSON.stringify(data.record));
              this.props.history.replace({
                pathname: `${match.path}panel/dashboard`,
                state: { loginInfo: data.record },
              });
            } else {
              this.doGetIP(data.record, params);
            }
          } else {
            this.setState({ errorMessage: data.message, userPassword: "" });
          }
        })
        .catch((err) => {
          this.setState({
            showLoadingSubmit: false,
          });
          console.log(err);
        });
    }
  };
  renderError = () => {
    if (this.state.errorMessage !== "") {
      return this.state.errorMessage;
    }
  };

  handleCloseValidationAlert = () => {
    this.setState({
      openValidation: false,
      titleValidation: "",
      titleBody: "",
    });
  };

  renderValidationAlert = () => {
    return (
      <Dialog
        open={this.state.openValidation}
        onClose={this.handleCloseValidationAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        fullWidth="xs"
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="label-title-alert">{this.state.titleValidation}</div>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <div className="title-body-alert"> {this.state.titleBody} </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton
            style={{ backgroundColor: "#f2f2f2" }}
            onClick={this.handleCloseValidationAlert}
          >
            <Close />
          </IconButton>
        </DialogActions>
      </Dialog>
    );
  };

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Grid container component="main" sx={{ height: "100vh" }}>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundImage:
                "url(https://zskor.com/api/images/background-login.jpeg)",
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div className="logo">
                <img
                  width="100"
                  src={require("../../../Assets/Images/icon_double_dribble.png")}
                  alt="logo"
                  style={{ margin: 0 }}
                />
              </div>
              <Typography component="h1" variant="h5">
                Double Dribble
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={this.handleSubmit}
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  autoComplete="username"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
                <div className="login-error">{this.renderError()}</div>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  style={{ backgroundColor: "#fc0207", fontWeight: "bold" }}
                >
                  {this.state.showLoadingSubmit === true ? (
                    <CircularProgress style={{ color: "#fff" }} size={24} />
                  ) : (
                    <Typography
                      variant="button"
                      style={{
                        color: "#fff",
                        textTransform: "capitalize",
                      }}
                    >
                      Login
                    </Typography>
                  )}
                </Button>
                <Copyright sx={{ mt: 5 }} />
              </Box>
            </Box>
          </Grid>
        </Grid>
        {this.renderValidationAlert()}
      </ThemeProvider>
    );
  }
}
export default Login;
