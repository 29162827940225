import React, { Component } from "react";
import DefaultUserImg from "../../../Assets/Images/user-default-image.png";
import { Typography } from "@mui/material";
import "./Header.style.css";
import { activeLanguage } from "../../../config";
import { getLanguage } from "../../../languages";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const stylesListComent = {
  inline: {
    display: "inline",
  },
};

class Header extends Component {
  constructor(props) {
    super(props);
    this.language = getLanguage(activeLanguage, "header");
    this.state = {
      loginInfo: props.loginInfo,
      communityList: props.communityList,
      community: props.community,
      showCommunityOption: false,
      communityOptionJustOpen: false,
      communityName: "",
      open: false,
      role: 0,
      menus: [],
      currentParent: 0,
      currentChild: -1,
      count: 0,
    };
  }

  componentDidMount = () => {
    document.addEventListener("click", this.eventDocumentClick);
    this.getRoleData();
  };
  componentWillUnmount() {
    document.removeEventListener("click", this.eventDocumentClick);
  }

  componentWillReceiveProps(props) {
    this.setState({
      loginInfo: props.loginInfo,
      community: props.community,
      communityList: props.communityList,
    });

    if (props.community !== this.state.community) {
      this.selectRole(props.loginInfo, props.community);
    }

    this.setState({ loginInfo: props.loginInfo, community: props.community });
  }

  handleDrawerOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleDrawerClose = () => {
    this.setState({
      open: false,
    });
  };

  eventDocumentClick = () => {
    if (this.state.showCommunityOption) {
      if (this.state.communityOptionJustOpen) {
        this.setState({ communityOptionJustOpen: false });
      } else {
        this.setState({ showCommunityOption: !this.state.showCommunityOption });
      }
    }
  };

  getRoleData = () => {
    let loginInfo = localStorage.getItem("loginInfo");
    if (
      loginInfo === undefined ||
      loginInfo === null ||
      loginInfo === "" ||
      loginInfo === "undefined" ||
      loginInfo === "null"
    ) {
      alert("Please login again");
      this.props.history.replace("/");
    } else {
      loginInfo = JSON.parse(loginInfo);
    }
  };

  doToogleMenuProfile = () => {
    this.props.toogleMenuProfile();
  };

  doToogleCommunityOption = () => {
    //this.props.toogleCommunityOption();
    this.setState({
      showCommunityOption: !this.state.showCommunityOption,
      communityOptionJustOpen: true,
    });
  };

  doChangeCommunity = (community) => {
    if (community.communityid !== this.state.community.communityid) {
      this.props.changeCommunity(community);
    }
  };

  menuSuperAdmin = (community) => {
    let tmpMenu = [];
    tmpMenu.push(
      {
        id: 0,
        label: this.language["dashboard"],
        to: "/panel/dashboard",
        openChild: false,
        childs: [],
      },
      {
        id: 2,
        label: "Master Data",
        to: "",
        openChild: false,
        childs: [
          {
            id: 20,
            label: "Website",
            to: "/panel/website",
          },
        ],
      },
      {
        id: 3,
        label: "CMS",
        to: "",
        openChild: false,
        childs: [
          {
            id: 30,
            label: "Banner",
            to: "/panel/banner",
          },
          {
            id: 31,
            label: "Logo",
            to: "/panel/logo",
          },
          {
            id: 32,
            label: "Contact",
            to: "/panel/kontak",
          },
        ],
      },
      {
        id: 4,
        label: "Settings",
        to: "",
        openChild: false,
        childs: [
          {
            id: 40,
            label: "User",
            to: "/panel/user",
          },
        ],
      }
    );

    this.setState({
      menus: tmpMenu,
    });
  };

  menuSubAdmin = (role) => {
    this.setState({
      menus: [
        {
          id: 0,
          label: "Dashboard",
          to: "/panel/dashboard",
          openChild: false,
          childs: [],
        },
        {
          id: 2,
          label: "Master Data",
          to: "",
          openChild: false,
          childs: [
            {
              id: 20,
              label: "Website",
              to: "/panel/website",
            },
          ],
        },
        {
          id: 3,
          label: "CMS",
          to: "",
          openChild: false,
          childs: [
            {
              id: 30,
              label: "Banner",
              to: "/panel/banner",
            },
            {
              id: 31,
              label: "Logo",
              to: "/panel/logo",
            },
            {
              id: 32,
              label: "Contact",
              to: "/panel/kontak",
            },
          ],
        },
      ],
    });
  };

  adminMerchant = (role) => {
    this.setState({
      menus: [
        {
          id: 0,
          label: "Dashboard",
          to: "/panel/dashboard",
          openChild: false,
          childs: [],
        },
      ],
    });
  };

  selectRole = (loginInfo, community) => {
    this.setState({ role: loginInfo.logintype });
    this.selectType(loginInfo.logintype, community, loginInfo.agent);
  };

  selectType = (role, community, agent) => {
    if (role === 1) {
      this.menuSuperAdmin(community);
    } else if (role === 2) {
      this.menuSubAdmin(community);
    }
  };

  toogleSubMenu = (menu, idx) => {
    let menus = this.state.menus;

    menus[idx].openChild = !menus[idx].openChild;
    this.setState({ menus: menus });
  };

  updateParent = (id) => {
    this.setState({ currentParent: id, currentChild: -1 });
  };

  updateChild = (id) => {
    this.setState({ currentChild: id, currentParent: -1, open: false });
  };

  eventDocumentClick = () => {
    if (this.state.showCommunityOption) {
      if (this.state.communityOptionJustOpen) {
        this.setState({ communityOptionJustOpen: false });
      } else {
        this.setState({ showCommunityOption: !this.state.showCommunityOption });
      }
    }
  };

  doToogleMenuProfile = () => {
    this.props.toogleMenuProfile();
  };

  doToogleCommunityOption = () => {
    //this.props.toogleCommunityOption();
    this.setState({
      showCommunityOption: !this.state.showCommunityOption,
      communityOptionJustOpen: true,
    });
  };

  doChangeCommunity = (community) => {
    if (community.communityid !== this.state.community.communityid) {
      this.props.changeCommunity(community);
    }
  };

  renderCommunityOption = () => {
    if (this.state.showCommunityOption) {
      return (
        <div className="select-community-option-container">
          {this.state.communityList.map((comm, i) => (
            <div
              key={comm.communityid}
              className="select-community-option"
              onClick={() => this.doChangeCommunity(comm)}
            >
              {comm.communityname}
            </div>
          ))}
        </div>
      );
    }
  };

  renderProfilePic = () => {
    if (
      this.state.loginInfo.profilepic === "" ||
      this.state.loginInfo.profilepic === undefined
    ) {
      return <img src={DefaultUserImg} alt="defaultpic" />;
    } else
      return <img src={this.state.loginInfo.profilepic} alt="profilepic" />;
  };

  renderforBadge = (submenu) => {
    // console.log(submenu);
    if (submenu.label === "Order List") {
      return (
        <div
          className={`submenu ${
            this.state.currentChild === submenu.id ? "menu-active" : ""
          } `}
        >
          <Typography
            component="span"
            variant="subtitle2"
            style={
              (stylesListComent.inline,
              {
                color: "#fff",
                fontWeight: "bolder",
              })
            }
          >
            {submenu.label}
          </Typography>
        </div>
      );
    } else {
      return (
        <div
          className={`submenu ${
            this.state.currentChild === submenu.id ? "menu-active" : ""
          } `}
        >
          <Typography
            component="span"
            variant="subtitle2"
            style={
              (stylesListComent.inline,
              {
                color: "#fff",
                fontWeight: "bolder",
              })
            }
          >
            {submenu.label}
          </Typography>
        </div>
      );
    }
  };

  _renderSubMenu = (menu, idx) => {
    if (menu.openChild) {
      if (
        menu.label === "Marketplace" &&
        menu.childs[2].label === "Order List"
      ) {
        return (
          <div className="submenu-container">
            {menu.childs.map((submenu, i) => (
              <Link
                key={submenu.id}
                to={submenu.to}
                onClick={() => this.updateChild(submenu.id)}
                className={`link-custom`}
              >
                {this.renderforBadge(submenu)}
              </Link>
            ))}
          </div>
        );
      }
      return (
        <div className="submenu-container">
          {menu.childs.map((submenu, i) => (
            <Link
              key={submenu.id}
              to={submenu.to}
              onClick={() => this.updateChild(submenu.id)}
              className={`link-custom`}
            >
              <div
                className={`submenu ${
                  this.state.currentChild === submenu.id ? "menu-active" : ""
                } `}
              >
                <Typography
                  component="span"
                  variant="subtitle2"
                  style={
                    (stylesListComent.inline,
                    {
                      color: "#fff",
                      fontWeight: "bolder",
                    })
                  }
                >
                  {submenu.label}
                </Typography>
              </div>
            </Link>
          ))}
        </div>
      );
    } else {
      return "";
    }
  };

  _renderMenu = (menu, i) => {
    if (menu.to === "") {
      if (menu.label === "Marketplace") {
        return (
          <div key={menu.id}>
            <div
              className="menu menu-parent"
              onClick={() => this.toogleSubMenu(menu, i)}
            >
              <Typography
                component="span"
                variant="subtitle2"
                style={
                  (stylesListComent.inline,
                  {
                    color: "#fff",
                    fontWeight: "bolder",
                  })
                }
              >
                {menu.label}
              </Typography>
              <div className="chevron-container">
                {this._renderChevronIcon(menu)}
              </div>
            </div>
            {this._renderSubMenu(menu, i)}
          </div>
        );
      }
      return (
        <div key={menu.id}>
          <div
            className="menu menu-parent"
            onClick={() => this.toogleSubMenu(menu, i)}
          >
            <Typography
              component="span"
              variant="subtitle2"
              style={
                (stylesListComent.inline,
                {
                  color: "#fff",
                  fontWeight: "bolder",
                })
              }
            >
              {menu.label}
            </Typography>
            <div className="chevron-container">
              {this._renderChevronIcon(menu)}
            </div>
          </div>
          {this._renderSubMenu(menu, i)}
        </div>
      );
    } else {
      return (
        <Link
          key={menu.id}
          to={menu.to}
          onClick={() => this.updateParent(menu.id)}
          className={`link-custom`}
        >
          <div
            className={`menu ${
              this.state.currentParent === menu.id ? "menu-active" : ""
            } `}
          >
            <Typography
              component="span"
              variant="subtitle2"
              style={
                (stylesListComent.inline,
                {
                  color: "#fff",
                  fontWeight: "bolder",
                })
              }
            >
              {menu.label}
            </Typography>{" "}
            <div className="chevron-container"></div>
          </div>
        </Link>
      );
    }
  };

  _renderChevronIcon = (menu) => {
    if (menu.openChild) {
      return <FontAwesomeIcon icon="chevron-down" />;
    } else {
      return <FontAwesomeIcon icon="chevron-left" />;
    }
  };

  render() {
    return (
      <div className="header-container">
        <div className="header-screen-desktop">
          <div className="header-title">
            {" "}
            <img
              style={{
                width: "70px",
                height: "50px",
              }}
              src={require("../../../Assets/Images/icon_double_dribble.png")}
              alt="logo"
            />
          </div>
          <div
            className="header-profilepic"
            onClick={() => this.doToogleMenuProfile()}
          >
            <div className="header-profileimg">{this.renderProfilePic()}</div>
          </div>
          <div className="header-profilename">
            <Typography
              component="span"
              variant="subtitle2"
              style={
                (stylesListComent.inline,
                {
                  color: "#000",
                  fontWeight: "bolder",
                })
              }
            >
              {this.state.loginInfo.username}
            </Typography>
          </div>
        </div>
      </div>
    );
  }
}
export default Header;
